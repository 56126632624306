import { shallow } from "zustand/shallow";

import { useNewAnswersStore } from "../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../store/newPreviewAnswersStore";

// import { useNewPreviewAnswersStore } from "../store/newPreviewAnswersStore";

export const useSurveyAnswer = (preguntaId: string): any | undefined => {
  const {
    currentPageId,
    entityId,
    isPreviewSurvey,
    surveyEntityAnswers,
    surveyId,
  } = useNewAnswersStore(
    ({
      currentPageId,
      entityId,
      isPreviewSurvey,
      surveyEntityAnswers,
      surveyId,
    }) => ({
      currentPageId,
      entityId,
      isPreviewSurvey,
      surveyEntityAnswers,
      surveyId,
    }),
    shallow
  );

  const previewSurveyAnswers = useNewPreviewAnswersStore(
    (state) => state.previewSurveyAnswers,
    shallow
  );

  if (isPreviewSurvey) {
    if (!previewSurveyAnswers?.[surveyId]?.[currentPageId]?.[preguntaId]) {
      return undefined;
    }

    return previewSurveyAnswers[surveyId][currentPageId][preguntaId];
  }

  if (
    !surveyEntityAnswers?.[entityId]?.[surveyId]?.[currentPageId]?.[preguntaId]
  ) {
    return undefined;
  }

  return surveyEntityAnswers[entityId][surveyId][currentPageId][preguntaId];
};
