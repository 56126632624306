import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type TituloColorState = {
  tituloFontFamily: string;
  tituloFontColor: string;
};

type TituloColorActions = {
  setTituloFontFamily: (tituloFontFamily: string) => void;
  setTituloFontColor: (tituloFontColor: string) => void;

  setAllTituloStyles: (tituloStyle: TituloColorState) => void;
};

const initialTituloColorState: TituloColorState = {
  tituloFontFamily: "Hanken Grotesk",
  tituloFontColor: "#000000",
};

export const useColorTituloStore = create<
  TituloColorState & TituloColorActions
>()(
  persist(
    (set, get) => ({
      ...initialTituloColorState,
      setTituloFontFamily: (tituloFontFamily) =>
        set((state) => ({ ...state, tituloFontFamily })),
      setTituloFontColor: (tituloFontColor) =>
        set((state) => ({ ...state, tituloFontColor })),
      setAllTituloStyles: ({ tituloFontColor, tituloFontFamily }) => {
        set({ tituloFontColor, tituloFontFamily });
      },
    }),
    {
      name: "creatio-survey-color-titulo-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<TituloColorState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
