import * as React from "react";
import { useTranslation } from "react-i18next";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useErrorsStore } from "../../store/errorStore";
import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";

type FileUploadQuestionType = {
  isRequired?: boolean;
  multiple: boolean;
  preguntaId: string;
};

const FileUploadQuestion: React.FC<FileUploadQuestionType> = ({
  isRequired,
  multiple,
  preguntaId,
}) => {
  const [fileName, setFileName] = React.useState<string>("");
  const [isEmptyError, setIsEmptyError] = React.useState<boolean>(false);

  const files = useSurveyAnswer(preguntaId);

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const isGlobalNextButtonClicked = useErrorsStore(
    (state) => state.isGlobalNextButtonClicked,
    shallow
  );

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const addEntityAnswer = useNewAnswersStore((state) => state.addEntityAnswer);

  const addEntityError = useErrorsStore(
    React.useCallback((state) => state.addEntityError, [])
  );

  const globalNextButtonClicked = useErrorsStore(
    React.useCallback((state) => state.globalNextButtonClicked, [])
  );

  const { t } = useTranslation();

  const handleUploadFile = (file: any) => {
    if (multiple && file) {
      const stringFiles = Object.values<File>(file).map((file) => {
        return file.name;
      });
      setFileName(stringFiles.toString());
      if (!isPreviewSurvey) {
        addEntityAnswer(preguntaId, file);
        if (isRequired) {
          setIsEmptyError(false);
        }
      } else {
        addPreviewAnswer(preguntaId, file);
      }
    } else if (file) {
      setFileName(file.name);
      if (!isPreviewSurvey) {
        addEntityAnswer(preguntaId, file);
        if (isRequired) {
          setIsEmptyError(false);
        }
      } else {
        addPreviewAnswer(preguntaId, file);
      }
    }
  };

  React.useEffect(() => {
    if (multiple && files) {
      const stringFiles = Object.values<File>(files).map((file) => {
        return file.name;
      });
      setFileName(stringFiles.toString());
    } else if (files) {
      setFileName(files.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files, multiple]);

  React.useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      addEntityError(preguntaId, files === undefined);
      setIsEmptyError(files === undefined);

      globalNextButtonClicked(false);
    }
  }, [
    addEntityError,
    files,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
  ]);

  React.useEffect(() => {
    if (isRequired) {
      if (files) {
        addEntityError(preguntaId, false);
      } else {
        addEntityError(preguntaId, true);
      }
    }
  }, [addEntityError, files, isRequired, preguntaId]);

  return (
    <React.Fragment>
      <TextField
        id="file-input"
        fullWidth
        name="fileName"
        value={fileName}
        variant="filled"
        placeholder={t<string>(
          multiple ? "messages.selectFiles" : "messages.selectFile"
        )}
        InputProps={{
          endAdornment: (
            <Grid container sx={{ width: "50%" }}>
              <Grid
                item
                xs
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Box sx={{ display: "flex", height: "auto" }}>
                  <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    sx={{ width: "100%" }}
                  >
                    {t(
                      multiple ? "messages.selectFiles" : "messages.selectFile"
                    )}
                    <input
                      hidden
                      id="subir-archivo-button"
                      type="file"
                      name="file"
                      accept=".pdf,.doc,.docx,.jpg,.png"
                      multiple={multiple}
                      onChange={(e: any) =>
                        handleUploadFile(
                          multiple
                            ? e.currentTarget.files
                            : e.currentTarget.files[0]
                        )
                      }
                    />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    sx={{
                      marginLeft: "16px",
                      minWidth: "0",
                    }}
                    onClick={() => {
                      setFileName("");
                      addEntityAnswer(preguntaId, null);
                      if (isRequired) {
                        setIsEmptyError(true);
                      }
                    }}
                  >
                    -
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ),
          readOnly: true,
          disableUnderline: true,
          sx: {
            borderRadius: "6px",
            ".MuiInputBase-input": {
              paddingBottom: "16px",
              paddingTop: "16px",
            },
            width: "100%",
          },
        }}
      />
      {isEmptyError && <ErrorMessage messageType="required" />}
    </React.Fragment>
  );
};

export default FileUploadQuestion;
