import { createTheme } from "@mui/material/styles";

const createEncuestaTheme = (main: string, fontFamily: string, color: string) =>
  createTheme({
    palette: {
      primary: {
        main,
      },
    },
    typography: {
      allVariants: {
        fontFamily,
        color,
      },
    },
  });

const createTitulosTheme = (fontFamily: string, color: string) =>
  createTheme({
    typography: {
      allVariants: {
        fontFamily,
        color,
      },
    },
  });

const createPreguntasTheme = (
  fontFamily: string,
  color: string
) =>
  createTheme({
    typography: {
      allVariants: {
        fontFamily,
        color,
      },
    },
  });

const createRespuestasTheme = (
  main: string,
  fontFamily: string,
  color: string
) =>
  createTheme({
    palette: {
      primary: {
        main,
      },
    },
    typography: {
      allVariants: {
        fontFamily,
        color,
      },
    },
  });

export {
  createEncuestaTheme,
  createPreguntasTheme,
  createRespuestasTheme,
  createTitulosTheme,
};
