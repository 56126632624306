import * as React from "react";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import { errorMessageType } from "../../types";

type ErrorMessageType = {
  messageType: errorMessageType;
  minNumber?: string;
  maxNumber?: string;
};

const ErrorMessage: React.FC<ErrorMessageType> = ({
  messageType,
  minNumber,
  maxNumber,
}) => {
  const [message, setMessage] = React.useState<string>("");

  const { t } = useTranslation();

  React.useEffect(() => {
    switch (messageType) {
      case "invalidDate":
        setMessage(t("errorMessage.invalidDate")!);
        break;

      case "invalidEmail":
        setMessage(t("errorMessage.invalidEmail")!);
        break;

      case "invalidNumber":
        setMessage(t("errorMessage.invalidNumber")!);
        break;

      case "required":
        setMessage(t("errorMessage.required")!);
        break;

      case "valueBetween01":
        setMessage(
          t("errorMessage.valueBetween01", { min: minNumber, max: maxNumber })!
        );
        break;

      default:
        setMessage(t("errorMessage.required")!);
        break;
    }
  }, [maxNumber, messageType, minNumber, t]);

  return (
    <Typography variant="h6" color="error">
      {message}
    </Typography>
  );
};

export default ErrorMessage;
