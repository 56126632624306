import React from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

const UpdateDataMessage = () => {
  const { t } = useTranslation();

  return (
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        {t("messages.surveySent")}
      </Typography>
      <Typography>
        {t("messages.updateYourContactInformation")}{" "}
        <Link href="/updatedata" target="_blank">
        {t("messages.linkHere")}
        </Link>
        .
      </Typography>
    </Box>
  );
};

export default UpdateDataMessage;
