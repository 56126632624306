import * as React from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";

//Icons
import { MaterialIcons } from "../../icons/Material/MaterialIcons";

//Components
import BoxImage from "../MatrizImage/BoxImage";
import CustomProgressBars from "../CustomProgressBar/CustomProgressBar";
import SurveyQuestions from "../SurveyQuestions/SurveyQuestions";
import UpdateDataMessage from "../UpdateDataMessage/UpdateDataMessage";

import { useColorEncuestaStore } from "../../store/colorEncuestaStore";
import { useColorPreguntasStore } from "../../store/colorPreguntasStore";
import { useColorRespuestasStore } from "../../store/colorRespuestasStore";
import { useColorTituloStore } from "../../store/colorTituloStore";
import { useErrorsStore } from "../../store/errorStore";

import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";

import {
  BGlobalPagesType,
  BGlobalQuestionType,
  BGlobalSurveyQuestion,
  BGlobalSurveyStyleType,
  BGlogalSurveyType,
} from "../../types";

function Copyright({ footerMessage }: { footerMessage: string }) {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {footerMessage}
    </Typography>
  );
}

type SurveyType = {
  entidadId: string;
  isPreview: boolean;
  isSendAnswers: boolean;
  survey: BGlogalSurveyType | undefined;

  handleClickSettingsButton: () => void;
};

const Survey: React.FC<SurveyType> = ({
  entidadId,
  handleClickSettingsButton,
  isPreview,
  isSendAnswers,
  survey,
}) => {
  const [pages, setPages] = React.useState<BGlobalPagesType[]>([]);
  const [backgroundImage, setBackgroundImage] = React.useState<any>(null);
  const [backgroundImageId, setBackgroundImageId] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isLoadingPage, setIsLoadingPage] = React.useState<boolean>(true);
  const [isLoadingCmpt, setIsLoadingCmpt] = React.useState<boolean>(true);
  const [disabledButton, setDisabledButton] = React.useState<boolean>(true);
  const [steps, setSteps] = React.useState<any[]>([]);
  const [questionId, setQuestionId] = React.useState<string>("");
  const [questions, setQuestions] = React.useState<BGlobalQuestionType[]>([]);
  const [condQuestions, setCondQuestions] = React.useState<
    BGlobalQuestionType[]
  >([]);

  const { t } = useTranslation();

  const { finishSurvey, surveyUpdateData } = useNewAnswersStore(
    ({ finishSurvey, surveyUpdateData }) => ({
      finishSurvey,
      surveyUpdateData,
    }),
    shallow
  );

  const {
    addQuestion,
    returnPreviewSurveyCurrentPage,
    resetSurveyAnswers,
    returnSurveyCurrentPage,
    sendAllAnswers,
    setCurrentPageId,
    setFinishSurvey,
    setSurveyCurrentPage,
    setSurveyIsFisihed,
    setSurveyPreviewCurrentPage,
  } = useNewAnswersStore(
    ({
      addQuestion,
      returnPreviewSurveyCurrentPage,
      resetSurveyAnswers,
      returnSurveyCurrentPage,
      sendAllAnswers,
      setCurrentPageId,
      setFinishSurvey,
      setSurveyCurrentPage,
      setSurveyIsFisihed,
      setSurveyPreviewCurrentPage,
    }) => ({
      addQuestion,
      returnPreviewSurveyCurrentPage,
      resetSurveyAnswers,
      returnSurveyCurrentPage,
      sendAllAnswers,
      setCurrentPageId,
      setFinishSurvey,
      setSurveyCurrentPage,
      setSurveyIsFisihed,
      setSurveyPreviewCurrentPage,
    })
  );

  const setPreviewCurrentPageId = useNewPreviewAnswersStore(
    (state) => state.setPreviewCurrentPageId
  );

  const {
    requiredQuestions,
    setErrorCurrentPageId,
    setErrorSurveyCurrentPage,
  } = useErrorsStore(
    ({
      requiredQuestions,
      setErrorCurrentPageId,
      setErrorSurveyCurrentPage,
    }) => ({
      requiredQuestions,
      setErrorCurrentPageId,
      setErrorSurveyCurrentPage,
    })
  );

  const returnErrorSurveyEntity = useErrorsStore(
    (state) => state.returnErrorSurveyEntity
  );

  const setAllEncuestaStyles = useColorEncuestaStore(
    (state) => state.setAllEncuestaStyles
  );

  const setAllPreguntaStyles = useColorPreguntasStore(
    (state) => state.setAllPreguntaStyles
  );

  const setAllRespuestaStyles = useColorRespuestasStore(
    (state) => state.setAllRespuestaStyles
  );

  const setAllTituloStyles = useColorTituloStore(
    (state) => state.setAllTituloStyles
  );

  const activeStep = isSendAnswers
    ? returnSurveyCurrentPage()
    : returnPreviewSurveyCurrentPage();

  const { globalNextButtonClicked, resetErrorSurveyAnswers } = useErrorsStore(
    ({ globalNextButtonClicked, resetErrorSurveyAnswers }) => ({
      globalNextButtonClicked,
      resetErrorSurveyAnswers,
    })
  );

  const handleNextSendAnswers = async () => {
    console.log("el boton es true");

    globalNextButtonClicked(true);

    const canContinue = () => {
      const errorSurveyEntityAnswers = returnErrorSurveyEntity();
      console.log("errorAnswers", errorSurveyEntityAnswers);
      const areAllQuestionsValid = Object.values(
        errorSurveyEntityAnswers
      ).every((errorAnswer) => !errorAnswer);

      if (Object.values(errorSurveyEntityAnswers).length > 0) {
        if (areAllQuestionsValid) {
          return true;
        } else if (requiredQuestions === 0) {
          return true;
        } else if (Object.values(errorSurveyEntityAnswers).length === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return requiredQuestions === 0;
      }
    };

    if (canContinue()) {
      setDisabledButton(true);
      console.log("el boton es false");
      globalNextButtonClicked(false);
      // setClickNextButton(true);
      setIsLoadingPage(true);
      if (finishSurvey) {
        if (survey) {
          setSurveyCurrentPage(entidadId, steps.length);
          setErrorSurveyCurrentPage(survey.Id, steps.length);

          sendAllAnswers();
        }
      } else {
        if (survey) {
          if (steps.length === activeStep + 1) {
            sendAllAnswers();
          }
          setSurveyCurrentPage(entidadId, activeStep + 1);
          setErrorSurveyCurrentPage(survey.Id, activeStep + 1);
        }
        if (activeStep !== steps.length - 1) {
          setQuestionId(pages[activeStep + 1].Id);
        }
      }
    }
  };

  const handleNext = async () => {
    // setClickNextButton(true);
    if (activeStep !== steps.length - 1) {
      setDisabledButton(true);
    }
    setIsLoadingPage(true);
    if (finishSurvey) {
      if (survey) {
        setSurveyPreviewCurrentPage(survey.Id, steps.length);
        // setErrorSurveyCurrentPage(survey.Id, steps.length);
      }
    } else {
      if (survey) {
        setSurveyPreviewCurrentPage(survey.Id, activeStep + 1);
        // setErrorSurveyCurrentPage(survey.Id, activeStep + 1);
      }
      if (activeStep !== steps.length - 1) {
        setQuestionId(pages[activeStep + 1].Id);
      }
    }
  };

  const handleBack = () => {
    // setClickNextButton(false);
    if (activeStep !== steps.length - 1) {
      setDisabledButton(true);
    }
    globalNextButtonClicked(false);
    setIsLoadingPage(true);
    if (survey) {
      if (isSendAnswers) {
        setSurveyCurrentPage(entidadId, activeStep - 1);
        setErrorSurveyCurrentPage(survey.Id, activeStep - 1);
      } else {
        setSurveyPreviewCurrentPage(survey.Id, activeStep - 1);
      }
    }
    setQuestionId(pages[activeStep - 1].Id);
  };

  const handleGoToStart = () => {
    setDisabledButton(true);
    setFinishSurvey(false);
    globalNextButtonClicked(false);
    resetSurveyAnswers();
    resetErrorSurveyAnswers();
    setQuestionId(pages[0].Id);
    if (survey) {
      if (isSendAnswers) {
        setSurveyCurrentPage(entidadId, 0);
        setErrorSurveyCurrentPage(survey.Id, 0);
      } else {
        setSurveyPreviewCurrentPage(survey.Id, 0);
      }
    }
  };

  const handleRequiredQuestions = (value: boolean) => {
    console.log("handleRequiredQuestions", value);
  };

  React.useEffect(() => {
    const getImagen = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_DO_URL}/getimage/`,
          {
            imageId: backgroundImageId,
          }
        );
        setBackgroundImage(response.data);
      } catch (error) {
        console.log("BoxImage - error ", error);
      }
    };

    if (backgroundImageId) {
      getImagen();
    }
  }, [backgroundImageId]);

  React.useEffect(() => {
    console.log("activeSurvey", survey);
    if (survey && survey.Id) {
      setIsLoading(true);
      axios
        .post<BGlobalPagesType[]>(`${process.env.REACT_APP_DO_URL}/getpages`, {
          surveyId: survey.Id,
        })
        .then((res) => {
          console.log("paginas", res.data);
          setPages(res.data);
          setQuestionId(res.data[activeStep].Id);
          setBackgroundImageId(survey.BGBackgroundId);
          if (res.data.length > 0) {
            setSteps(res.data);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("getpages - error ", err);
          setIsLoading(false);
        });

      axios
        .post<BGlobalSurveyStyleType>(
          `${process.env.REACT_APP_DO_URL}/getstyles`,
          {
            surveyId: survey.Id,
          }
        )
        .then((res) => {
          console.log("ESTILOS", res.data);

          if (res.data) {
            setAllEncuestaStyles({
              encuestaBackgroundColor: res.data.BGSurveyBC || "#000000",
              encuestaFontColor: res.data.BGSurveyFC || "#000000",
              encuestaFontFamily: res.data.BGSurveyFT || "Hanken Grotesk",
            });

            setAllPreguntaStyles({
              preguntaFontColor: res.data.BGQuestionFC || "#000000",
              preguntaFontFamily: res.data.BGQuestionFT || "Hanken Grotesk",
            });

            setAllRespuestaStyles({
              respuestaBackgroundColor: res.data.BGAnswerBC || "#000000",
              respuestaFontColor: res.data.BGAnswerFC || "#000000",
              respuestaFontFamily: res.data.BGAnswerFT || "Hanken Grotesk",
            });

            setAllTituloStyles({
              tituloFontColor: res.data.BGTitleFC || "#000000",
              tituloFontFamily: res.data.BGTitleFT || "Hanken Grotesk",
            });
          }

          setIsLoading(false);
        })
        .catch((err) => {
          console.error("ESTILOS - error ", err);
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [survey]);

  React.useEffect(() => {
    setIsLoadingPage(true);
    if (questionId) {
      setCurrentPageId(questionId);
      setPreviewCurrentPageId(questionId);
      setErrorCurrentPageId(questionId);
      axios
        .post<BGlobalSurveyQuestion>(
          `${process.env.REACT_APP_DO_URL}/getquestion`,
          {
            questionId,
          }
        )
        .then((res) => {
          console.log(`Pag${activeStep + 1} - getquestion`, res.data.value);

          const conditionalQuestions: any[] = [];
          for (let index = 0; index < res.data.value.length; index++) {
            const element = res.data.value[index];
            if (
              element.BGlobalDependenceQuestionId !==
              "00000000-0000-0000-0000-000000000000"
            ) {
              console.log("ELEMENT", element);
              conditionalQuestions.push(element);
            }
          }
          setQuestions(res.data.value);
          addQuestion(res.data.value);
          setCondQuestions(conditionalQuestions);
          setIsLoadingPage(false);
        })
        .catch((err) => {
          console.error("getquestion - error ", err);
          setIsLoadingPage(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionId]);

  React.useEffect(() => {
    if (activeStep === steps.length) {
      setSurveyIsFisihed(true);
    } else {
      setSurveyIsFisihed(false);
    }
  }, [activeStep, setSurveyIsFisihed, steps.length]);

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="absolute"
          elevation={0}
          color="primary"
          sx={{ position: "relative" }}
        >
          <Toolbar>
            <Typography color="primary">BGlobal Solutions</Typography>

            <Box color="primary.main" sx={{ flexGrow: 1 }}>
              0
            </Box>
            {isPreview && (
              <Stack direction="row" spacing={4}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => handleClickSettingsButton()}
                  color="inherit"
                  id="menu-appbar"
                >
                  <MaterialIcons.SettingsIcon />
                </IconButton>
              </Stack>
            )}
          </Toolbar>
        </AppBar>
      </Box>
      <Container component="main" sx={{ mb: 4 }}>
        {isLoading ? (
          <Paper
            elevation={0}
            sx={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              mb: { xs: 3, md: 6 },
              p: { xs: 2, md: 3 },
            }}
          >
            <Box
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                display: "flex",
                justifyContent: "center",
                mb: { xs: 3, md: 6 },
                p: { xs: 2, md: 32 },
              }}
            >
              <CircularProgress />
            </Box>
          </Paper>
        ) : (
          <Zoom in={!isLoading}>
            <Grid container>
              <Grid item xs>
                <Paper
                  variant="elevation"
                  elevation={6}
                  sx={{
                    mt: { xs: 3, md: 6 },
                    pt: { xs: 2, md: 3 },
                  }}
                >
                  <Box display="flex" justifyContent="center">
                    <BoxImage
                      imageId={survey?.BGlobalSurveyImageId || ""}
                      text="logo-imagen"
                    />
                  </Box>
                  <Typography
                    component="h1"
                    variant="h4"
                    align="center"
                    paddingBottom={survey?.BGlobalSurveyDescription ? 2 : 4}
                    id="encuesta-name"
                  >
                    {survey?.BGlobalSurveyName}
                  </Typography>
                  {survey?.BGlobalSurveyDescription && (
                    <Typography
                      component="h1"
                      variant="subtitle1"
                      align="center"
                      paddingBottom={4}
                    >
                      {survey?.BGlobalSurveyDescription}
                    </Typography>
                  )}

                  <Box
                    sx={{
                      backgroundColor: "#f3f3f3",
                      height: "40px",
                      display: "flex",
                      placeContent: "center",
                      placeItems: "center",
                    }}
                  >
                    <CustomProgressBars
                      totalSteps={steps && steps.length > 0 ? steps.length : 0}
                      actualStep={activeStep}
                    />
                  </Box>
                </Paper>
                <Paper
                  variant="elevation"
                  elevation={6}
                  sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    mb: { xs: 3, md: 6 },
                    p: { xs: 2, md: 3 },
                    backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)),url(${`data:image/png;base64,${backgroundImage}`})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {activeStep === steps.length ? (
                    <>
                      {surveyUpdateData ? (
                        <UpdateDataMessage />
                      ) : (
                        <Typography variant="h5" gutterBottom>
                          {t("messages.surveySent")}
                        </Typography>
                      )}
                      {!isSendAnswers && (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent:
                                activeStep !== 0 ? "space-between" : "flex-end",
                            }}
                          >
                            {activeStep !== 0 && (
                              <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleBack}
                                disabled={disabledButton}
                                sx={{ marginTop: "12px" }}
                              >
                                {t("button.back")}
                              </Button>
                            )}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => handleGoToStart()}
                              disabled={disabledButton}
                              sx={{ marginTop: "12px", marginLeft: "8px" }}
                            >
                              {t("button.backToHome")}
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </>
                  ) : (
                    <React.Fragment>
                      {isLoadingPage ? (
                        <CircularProgress />
                      ) : (
                        <SurveyQuestions
                          questionSubtitle={pages[activeStep]?.BGlobalSubTitle}
                          questionTitle={pages[activeStep]?.BGlobalTitle}
                          requiredTitle={pages[activeStep].BGlobalShowTitle}
                          questions={questions}
                          condQuestions={condQuestions}
                          handleRequiredQuestions={handleRequiredQuestions}
                          handleLoadingCmpt={setIsLoadingCmpt}
                          handleDisabledButton={setDisabledButton}
                        />
                      )}
                      <Grid
                        container
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Grid item xs={4}>
                          <Box
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent:
                                  activeStep !== 0
                                    ? "space-between"
                                    : "flex-end",
                              }}
                            >
                              {activeStep !== 0 && (
                                <Button
                                  variant="outlined"
                                  onClick={handleBack}
                                  sx={{ mt: 3, ml: 1 }}
                                  disabled={finishSurvey || disabledButton}
                                  startIcon={
                                    <MaterialIcons.KeyboardArrowLeftIcon />
                                  }
                                >
                                  {t("button.back")}
                                </Button>
                              )}
                              {isSendAnswers ? (
                                <Button
                                  variant="contained"
                                  onClick={handleNextSendAnswers}
                                  sx={{ mt: 3, ml: 1 }}
                                  disabled={disabledButton}
                                  {...((activeStep < steps.length - 1 ||
                                    !finishSurvey) && {
                                    endIcon: (
                                      <MaterialIcons.KeyboardArrowRightIcon />
                                    ),
                                  })}
                                >
                                  {activeStep === steps.length - 1 ||
                                  finishSurvey
                                    ? t("button.finish")
                                    : t("button.next")}
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  onClick={handleNext}
                                  sx={{ mt: 3, ml: 1 }}
                                  disabled={disabledButton}
                                  {...((activeStep < steps.length - 1 ||
                                    !finishSurvey) && {
                                    endIcon: (
                                      <MaterialIcons.KeyboardArrowRightIcon />
                                    ),
                                  })}
                                >
                                  {activeStep === steps.length - 1 ||
                                  finishSurvey
                                    ? t("button.finish")
                                    : t("button.next")}
                                </Button>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </Paper>
              </Grid>
            </Grid>
          </Zoom>
        )}
        <Copyright
          footerMessage={survey?.BGFooter || "Copyright © BGlobal 2023."}
        />
      </Container>
    </Box>
  );
};

export default Survey;
