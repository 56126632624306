import React, { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";

import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import CondAnswer from "../SurveyQuestions/CondAnswer";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useErrorsStore } from "../../store/errorStore";
import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";

import { BGlobalQuestionType } from "../../types";

type RadioQuestionType = {
  condQuestions?: any[];
  isRequired?: boolean;
  preguntaId: string;
  radioOptions: any[];
  todasPreguntas: any;
  isConditionalQuestion?: boolean;
};

const RadioQuestion: React.FC<RadioQuestionType> = ({
  condQuestions,
  isRequired,
  preguntaId,
  radioOptions,
  todasPreguntas,
  isConditionalQuestion,
}) => {
  const [showConditionalQuestion, setShowConditionalQuestion] =
    useState<boolean>(false);
  const [question, setQuestion] = useState<BGlobalQuestionType | null>(null);
  const [checkboxOptions, setCheckboxOptions] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [conditionalQuestion, setConditionalQuestion] = useState<any>();
  const [radioCondQuestion, setRadioCondQuestion] = useState<any>();
  const [isRequiredError, setIsRequiredError] = useState<boolean>(false);

  const isGlobalNextButtonClicked = useErrorsStore(
    (state) => state.isGlobalNextButtonClicked,
    shallow
  );

  const globalNextButtonClicked = useErrorsStore(
    useCallback((state) => state.globalNextButtonClicked, [])
  );

  const addEntityError = useErrorsStore(
    useCallback((state) => state.addEntityError, [])
  );

  const setFinishSurvey = useNewAnswersStore(
    useCallback((state) => state.setFinishSurvey, [])
  );

  const addEntityAnswer = useNewAnswersStore(
    useCallback((state) => state.addEntityAnswer, [])
  );

  const seleccionado = useSurveyAnswer(preguntaId);

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const handleRadioChange = (option: any) => {
    if (!isPreviewSurvey) {
      addEntityAnswer(preguntaId, option.Id);
    } else {
      addPreviewAnswer(preguntaId, option.Id);
    }
    setSelectedOption(option.Id);

    if (!isConditionalQuestion) {
      const encontrado = condQuestions?.find(
        (pregunta) => pregunta.BGlobalDependenceResponseId === option.Id
      );

      if (encontrado) {
        setQuestion(encontrado);
        console.log("ENCONTRADO", encontrado);
        const condQuestion: any = Object.values(todasPreguntas.preguntas).find(
          (pregunta: any) =>
            pregunta.conditionalQuestionId ===
            encontrado.BGlobalDependenceQuestionId
        );

        const radioCondQuestion: any = Object.values(
          todasPreguntas.preguntas
        ).find((pregunta: any) => pregunta.preguntaId === encontrado.Id);

        if (radioCondQuestion) {
          setRadioCondQuestion(radioCondQuestion);
        }

        console.log("RADIO COND QUESTION", radioCondQuestion);

        if (condQuestion) {
          setConditionalQuestion(condQuestion);

          if (condQuestion.respuesta.type === "checkbox") {
            setCheckboxOptions(condQuestion.respuesta.answers);
          }
        }

        setShowConditionalQuestion(true);
      } else {
        setQuestion(null);
        setShowConditionalQuestion(false);
      }
    }

    setFinishSurvey(option.BGlobalEndSurvey);
  };

  const renderRadioIcon = (isSelected: boolean) => {
    return isSelected ? (
      <MaterialIcons.CheckCircleIcon />
    ) : (
      <MaterialIcons.RadioButtonUncheckedIcon color="primary" />
    );
  };

  useEffect(() => {
    setSelectedOption(seleccionado || "");
  }, [seleccionado]);

  useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      addEntityError(preguntaId, selectedOption === "");
      setIsRequiredError(selectedOption === "");
      globalNextButtonClicked(false);
    }
  }, [
    addEntityError,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
    selectedOption,
  ]);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Stack spacing={2}>
            {radioOptions.map((option, index) => (
              <Paper
                key={option.Id}
                sx={{
                  backgroundColor: "inherit",
                  display: "flex",
                  borderRadius: "8px",
                }}
                elevation={4}
              >
                <FormControlLabel
                  key={option.Id}
                  control={
                    <Radio
                      checked={selectedOption === option.Id}
                      onChange={() => handleRadioChange(option)}
                      value={option.Id}
                      icon={renderRadioIcon(selectedOption === option.Id)}
                      checkedIcon={renderRadioIcon(
                        selectedOption === option.Id
                      )}
                      name={`radio-${index}-${option.Id}`}
                      id={`radio-${index}-${option.Id}`}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 60,
                        },
                        padding: 0,
                      }}
                    />
                  }
                  label={option.BGlobalValueText}
                  sx={{ paddingX: 2, paddingY: 1, flexGrow: 1 }}
                />
              </Paper>
            ))}
          </Stack>
          {isRequiredError && (
            <ErrorMessage messageType="required" />
          )}
        </Grid>
      </Grid>

      {showConditionalQuestion && question && !isConditionalQuestion && (
        <CondAnswer
          question={question}
          checkboxOptions={checkboxOptions}
          preguntaId={question.Id}
          condQuestions={condQuestions}
          condAnswers={conditionalQuestion}
          radioCondQuestion={radioCondQuestion}
        />
      )}
    </React.Fragment>
  );
};

export default RadioQuestion;
