import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type RespuestaColorState = {
  respuestaFontFamily: string;
  respuestaFontColor: string;
  respuestaBackgroundColor: string;
};

type RespuestaColorActions = {
  setRespuestaFontFamily: (fontFamily: string) => void;
  setRespuestaFontColor: (fontColor: string) => void;
  setRespuestaBackgroundColor: (backgroundColor: string) => void;

  setAllRespuestaStyles: (respuestaStyles: RespuestaColorState) => void;
};

const initialRespuestaColorState: RespuestaColorState = {
  respuestaFontFamily: "Hanken Grotesk",
  respuestaFontColor: "#000000",
  respuestaBackgroundColor: "#000000",
};

export const useColorRespuestasStore = create<
  RespuestaColorState & RespuestaColorActions
>()(
  persist(
    (set, get) => ({
      ...initialRespuestaColorState,
      setRespuestaFontFamily: (respuestaFontFamily) =>
        set((state) => ({ ...state, respuestaFontFamily })),
      setRespuestaFontColor: (respuestaFontColor) =>
        set((state) => ({ ...state, respuestaFontColor })),
      setRespuestaBackgroundColor: (respuestaBackgroundColor) =>
        set((state) => ({ ...state, respuestaBackgroundColor })),
      setAllRespuestaStyles: ({
        respuestaBackgroundColor,
        respuestaFontColor,
        respuestaFontFamily,
      }) => {
        set({
          respuestaBackgroundColor,
          respuestaFontColor,
          respuestaFontFamily,
        });
      },
    }),
    {
      name: "creatio-survey-color-respuestas-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<RespuestaColorState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
