import * as React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";

import {
  BGLookupsType,
  BGlobalAccountType,
  BGlobalAddressType,
  BGlobalCity,
  BGlobalCountry,
  BGlobalRegion,
} from "../../types";

type AsynchronusSelectType = {
  disabled?: boolean;
  label: string;
  name: string;
  nameId: string;
  required?: boolean;
  selectType: BGLookupsType;

  value: any;

  onChangeCountry: (
    name: string,
    value: any,
    nameId: string,
    valueId: string
  ) => void;
};

type OptionsType =
  | BGlobalCountry[]
  | BGlobalRegion[]
  | BGlobalCity[]
  | BGlobalAddressType[]
  | BGlobalAccountType[];

type OptionType =
  | BGlobalCountry
  | BGlobalRegion
  | BGlobalCity
  | BGlobalAddressType;

const AsynchronusSelect: React.FC<AsynchronusSelectType> = ({
  disabled,
  label,
  name,
  nameId,
  required,
  selectType,
  value,

  onChangeCountry,
}) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<OptionsType>([]);

  const { t } = useTranslation();

  const handleSelect = (
    name: string,
    newValueName: string,
    nameId: string,
    newValueId: string
  ) => {
    onChangeCountry(name, newValueName, nameId, newValueId);
  };

  const getCountryList = React.useCallback(() => {
    setIsLoading(true);
    axios
      .post<BGlobalCountry[]>(`${process.env.REACT_APP_DO_URL}/getcountries`)
      .then((res) => {
        console.log("Countries", res.data);
        setOptions(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("ERROR", err);
        setOptions([]);
        setIsLoading(false);
      });
  }, []);

  const getRegionList = React.useCallback(() => {
    setIsLoading(true);
    axios
      .post<BGlobalRegion[]>(`${process.env.REACT_APP_DO_URL}/getregionlist`, {
        countryId: value["CountryId"],
      })
      .then((res) => {
        console.log("Region List", res.data);
        setOptions(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("ERROR", err);
        setOptions([]);
        setIsLoading(false);
      });
  }, [value]);

  const getCityList = React.useCallback(() => {
    setIsLoading(true);
    axios
      .post<BGlobalCity[]>(`${process.env.REACT_APP_DO_URL}/getcitylist`, {
        regionId: value["RegionId"],
      })
      .then((res) => {
        console.log("Cities", res.data);
        setOptions(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("ERROR", err);
        setOptions([]);
        setIsLoading(false);
      });
  }, [value]);

  const getAccountList = React.useCallback(() => {
    setIsLoading(true);
    axios
      .get<BGlobalAccountType[]>(
        `${process.env.REACT_APP_DO_URL}/getaccountlist`
      )
      .then((res) => {
        console.log("Accounts", res.data);
        setOptions(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("ERROR", err);
        setOptions([]);
        setIsLoading(false);
      });
  }, []);

  const getAddressTypeList = React.useCallback(() => {
    setIsLoading(true);
    axios
      .post<BGlobalAddressType[]>(
        `${process.env.REACT_APP_DO_URL}/getaddresslist`
      )
      .then((res) => {
        console.log("Countries", res.data);
        setOptions(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("ERROR", err);
        setOptions([]);
        setIsLoading(false);
      });
  }, []);

  React.useEffect(() => {
    if (open) {
      if (selectType === "Country") {
        getCountryList();
      } else if (selectType === "City") {
        getCityList();
      } else if (selectType === "AddressType") {
        getAddressTypeList();
      } else if (selectType === "Account") {
        getAccountList();
      } else {
        getRegionList();
      }
    } else {
      setOptions([]);
    }
  }, [
    getAccountList,
    getAddressTypeList,
    getCityList,
    getCountryList,
    getRegionList,
    open,
    selectType,
  ]);

  return (
    <Autocomplete
      id="AsynchronusSelectSelect-asynchronous"
      fullWidth
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(_, newValue) => {
        handleSelect(name, newValue.Name, nameId, newValue.Id);
      }}
      isOptionEqualToValue={(option: OptionType, optionValue) =>
        option.Name === optionValue
      }
      noOptionsText={t("errorMessage.noOptions")}
      loadingText={t("messages.loading")}
      options={options}
      loading={isLoading}
      value={value[name]}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          name={name}
          label={label}
          margin="normal"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default AsynchronusSelect;
