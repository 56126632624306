import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type EncuestaColorState = {
  encuestaFontFamily: string;
  encuestaFontColor: string;
  encuestaBackgroundColor: string;
};

type EncuestaColorActions = {
  setEncuestaFontFamily: (fontFamily: string) => void;
  setEncuestaFontColor: (fontColor: string) => void;
  setEncuestaBackgroundColor: (backgroundColor: string) => void;

  setAllEncuestaStyles: (encuestaStyles: EncuestaColorState) => void;
};

const initialEncuestaColorState: EncuestaColorState = {
  encuestaFontFamily: "Hanken Grotesk",
  encuestaFontColor: "#000000",
  encuestaBackgroundColor: "#000000",
};

export const useColorEncuestaStore = create<
  EncuestaColorState & EncuestaColorActions
>()(
  persist(
    (set, get) => ({
      ...initialEncuestaColorState,
      setEncuestaFontFamily: (encuestaFontFamily) =>
        set((state) => ({ ...state, encuestaFontFamily })),
      setEncuestaFontColor: (encuestaFontColor) =>
        set((state) => ({ ...state, encuestaFontColor })),
      setEncuestaBackgroundColor: (encuestaBackgroundColor) =>
        set((state) => ({ ...state, encuestaBackgroundColor })),
      setAllEncuestaStyles: ({
        encuestaBackgroundColor,
        encuestaFontColor,
        encuestaFontFamily,
      }) => {
        set({
          encuestaBackgroundColor,
          encuestaFontColor,
          encuestaFontFamily,
        });
      },
    }),
    {
      name: "creatio-survey-survey-color-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<EncuestaColorState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
