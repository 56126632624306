import React, { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";

import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";
import { useErrorsStore } from "../../store/errorStore";

type MatrizSimpleType = {
  isRequired?: boolean;
  options: any[];
  preguntaId: string;
};

const MatrizSimple: React.FC<MatrizSimpleType> = ({
  isRequired,
  options,
  preguntaId,
}) => {
  const [agrupado, setAgrupado] = useState<any[]>([]);
  const [seleccionado, setSeleccionado] = useState<string>("");
  const [isRequiredError, setIsRequiredError] = useState<boolean>(false);

  const setFinishSurvey = useNewAnswersStore((state) => state.setFinishSurvey);

  const addEntityAnswer = useNewAnswersStore(
    React.useCallback((state) => state.addEntityAnswer, [])
  );

  const addEntityError = useErrorsStore(
    React.useCallback((state) => state.addEntityError, [])
  );

  const isGlobalNextButtonClicked = useErrorsStore(
    (state) => state.isGlobalNextButtonClicked,
    shallow
  );

  const globalNextButtonClicked = useErrorsStore(
    (state) => state.globalNextButtonClicked
  );

  const matrizSimpleAnswer = useSurveyAnswer(preguntaId);

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const handleRadioChange = useCallback(
    (option: any) => {
      if (!isPreviewSurvey) {
        addEntityAnswer(preguntaId, { [option.Id]: option.BGlobalValueText });
        setIsRequiredError(false);
      } else {
        addPreviewAnswer(preguntaId, { [option.Id]: option.BGlobalValueText });
      }
      setSeleccionado(option.Id);

      setFinishSurvey(option.BGlobalEndSurvey);
    },
    [
      addEntityAnswer,
      addPreviewAnswer,
      isPreviewSurvey,
      preguntaId,
      setFinishSurvey,
    ]
  );

  const renderRadioIcon = (isSelected: boolean) => {
    return isSelected ? (
      <MaterialIcons.CheckCircleIcon />
    ) : (
      <MaterialIcons.RadioButtonUncheckedIcon color="primary" />
    );
  };

  useEffect(() => {
    if (options.length > 0) {
      setAgrupado([...options]);
    }
  }, [options]);

  useEffect(() => {
    if (agrupado.length > 0 && !seleccionado) {
      for (let index = 0; index < agrupado.length; index++) {
        const option = agrupado[index];
        if (option.BGlobalIsDefault) {
          handleRadioChange(option);
          break;
        }
      }
    }
  }, [agrupado, handleRadioChange, seleccionado]);

  useEffect(() => {
    setSeleccionado(
      matrizSimpleAnswer ? Object.keys(matrizSimpleAnswer)[0] : ""
    );
  }, [matrizSimpleAnswer, preguntaId]);

  useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      if (seleccionado) {
        setIsRequiredError(false);
        addEntityError(preguntaId, false);
      } else {
        setIsRequiredError(true);
        addEntityError(preguntaId, true);
      }
      globalNextButtonClicked(false);
    }
  }, [
    addEntityError,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
    seleccionado,
  ]);

  return agrupado.length > 0 ? (
    <React.Fragment>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "transparent" }}
        elevation={0}
      >
        <Table>
          <TableHead>
            <TableRow>
              {agrupado.map((option: any, index: number) => (
                <TableCell
                  key={index}
                  align="center"
                  sx={{ borderBottom: "1px solid #dddddd" }}
                >
                  <Typography>{option.BGlobalValueText}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              {agrupado.map((option: any, index: number) => (
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{ border: "1px solid #dddddd", padding: 0 }}
                  key={index}
                >
                  <Radio
                    checked={seleccionado === option.Id}
                    onChange={() => handleRadioChange(option)}
                    value={option.Id}
                    icon={renderRadioIcon(false)}
                    checkedIcon={renderRadioIcon(true)}
                    name={`radio-${index}-${option.Id}`}
                    id={`radio-${index}-${option.Id}`}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 60,
                      },
                      padding: 0,
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {isRequiredError && <ErrorMessage messageType="required" />}
    </React.Fragment>
  ) : (
    <CircularProgress />
  );
};

export default MatrizSimple;
