import { MenuProps } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#f50057",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const paperProps = {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: 250,
  },
};

const menuProps: MenuProps = {
  anchorOrigin: { vertical: "bottom", horizontal: "center" },
  transformOrigin: { vertical: "top", horizontal: "center" },
  variant: "menu",
  open: false,
  PaperProps: paperProps,
};

const validateEmail = (email: string) => {
  // Expresión regular para validar el formato del email
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  return emailRegex.test(email);
};

const isInRange = (max: number, min: number, value: number) => {
  if (isNaN(value)) {
    return false;
  }

  if (value >= min && value <= max) {
    return true;
  }

  return false;
};

export { isInRange, menuProps, paperProps, useStyles, validateEmail };
