import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type AnswersErrorsState = {
  errorAnswers: Record<string, boolean>;
  errorCurrentPage: number;
  errorCurrentPageId: string;
  errorEntityId: string;
  errorSurveyAnswers: Record<string, any>;
  errorSurveyCurrentPage: number;
  errorSurveyId: string;
  errorSurveys: string[];
  errorSurveysCurrentPage: Record<string, any>;

  requiredQuestions: number;

  surveyEntityErrors: Record<string, any>;

  isGlobalNextButtonClicked: boolean;
};

type AnswersErrorsAction = {
  addAnswerError: (questionId: string, answer: boolean) => void;
  addEntityError: (questionId: string, hasError: boolean) => void;
  addErrorSurvey: (surveyId: string) => void;
  nextButtonClicked: (preguntaId: string, isClicked: boolean) => void;
  globalNextButtonClicked: (isClicked: boolean) => void;

  setErrorCurrentPage: (currentPage: number) => void;
  setErrorCurrentPageId: (errorCurrentPageId: string) => void;
  setErrorSurveyCurrentPage: (surveyId: string, currentPage: number) => void;
  setErrorEntityId: (errorEntityId: string) => void;
  setErrorSurveyId: (surveyId: string) => void;
  setRequiredQuestions: (requiredQuestions: number) => void;

  returnErrorSurveyCurrentPage: () => number;

  returnErrorSurveyEntity: () => Record<string, any>;

  resetErrorSurveyAnswers: () => void;
};

// define the initial state
const initialAnswersErrorsState: AnswersErrorsState = {
  errorAnswers: {},
  errorCurrentPage: 0,
  errorCurrentPageId: "",
  errorEntityId: "",
  errorSurveyAnswers: {},
  errorSurveyCurrentPage: 0,
  errorSurveyId: "",
  errorSurveys: [],
  errorSurveysCurrentPage: {},

  requiredQuestions: 0,

  surveyEntityErrors: {},

  isGlobalNextButtonClicked: false,
};

export const useErrorsStore = create<
  AnswersErrorsState & AnswersErrorsAction
>()(
  persist(
    (set, get) => ({
      ...initialAnswersErrorsState,
      addAnswerError: (questionId: string, answer: boolean) => {
        console.log("addAnswerError", answer);
        set((state) => ({
          ...state,
          errorAnswers: { ...state.errorAnswers, [questionId]: answer },
          errorSurveyAnswers: {
            ...state.errorSurveyAnswers,
            [get().errorSurveyId]: {
              ...state.errorSurveyAnswers[get().errorSurveyId],
              [get().errorCurrentPageId]: {
                ...(state.errorSurveyAnswers[get().errorSurveyId] && {
                  ...state.errorSurveyAnswers[get().errorSurveyId][
                    get().errorCurrentPageId
                  ],
                }),
                [questionId]: answer,
              },
            },
          },
        }));
      },
      addEntityError: (questionId: string, hasError: boolean) => {
        console.log("addEntityError", hasError);
        const { errorEntityId, errorSurveyId, errorCurrentPageId } = get();
        set((state) => ({
          ...state,

          surveyEntityErrors: {
            ...state.surveyEntityErrors,
            [errorEntityId]: {
              ...state.surveyEntityErrors?.[errorEntityId],
              [errorSurveyId]: {
                ...state.surveyEntityErrors?.[errorEntityId]?.[errorSurveyId],
                [errorCurrentPageId]: {
                  ...state.surveyEntityErrors?.[errorEntityId]?.[
                    errorSurveyId
                  ]?.[errorCurrentPageId],
                  [questionId]: hasError,
                },
              },
            },
          },
        }));
      },
      addErrorSurvey: (surveyId: string) => {
        console.log("addErrorSurvey");
        set((state) => ({
          ...state,
          errorSurveys: [...state.errorSurveys, surveyId],
        }));
      },
      nextButtonClicked: (preguntaId: string, isNextButtonClicked: boolean) => {
        console.log("nextButtonClicked", isNextButtonClicked);
        set((state) => ({
          ...state,
          [preguntaId]: isNextButtonClicked,
        }));
      },
      setErrorCurrentPage: (currentPage: number) => {
        console.log("setErrorCurrentPage");
        set((state) => ({ ...state, currentPage }));
      },
      setErrorCurrentPageId: (errorCurrentPageId) => {
        console.log("setErrorCurrentPageId");
        set((state) => ({
          ...state,
          errorCurrentPageId,
        }));
      },
      setErrorSurveyCurrentPage: (errorSurveyId, errorCurrentPage) => {
        set((state) => ({
          errorSurveysCurrentPage: {
            ...state.errorSurveysCurrentPage,
            [errorSurveyId]: { errorCurrentPage },
          },
        }));
      },
      setErrorSurveyId: (errorSurveyId: string) => {
        console.log("setErrorSurveyId");
        set((state) => ({ ...state, errorSurveyId }));
      },
      setErrorEntityId: (errorEntityId: string) => {
        console.log("setErrorEntityId");
        set((state) => ({ ...state, errorEntityId }));
      },
      setRequiredQuestions: (requiredQuestions: number) => {
        console.log("setRequiredQuestions");
        set((state) => ({ ...state, requiredQuestions }));
      },
      globalNextButtonClicked: (isGlobalNextButtonClicked: boolean) => {
        console.log("globalNextButtonClicked", isGlobalNextButtonClicked);
        set((state) => ({
          ...state,
          isGlobalNextButtonClicked,
        }));
      },
      returnErrorSurveyCurrentPage: () => {
        return (
          get().errorSurveysCurrentPage[get().errorSurveyId]
            ?.errorCurrentPage || get().errorCurrentPage
        );
      },
      returnErrorSurveyEntity: () => {
        const {
          errorEntityId,
          errorSurveyId,
          errorCurrentPageId,
          surveyEntityErrors,
        } = get();

        return (
          surveyEntityErrors?.[errorEntityId]?.[errorSurveyId]?.[
            errorCurrentPageId
          ] || {}
        );
      },
      resetErrorSurveyAnswers: () => {
        set(initialAnswersErrorsState);
      },
    }),
    {
      name: "creatio-error-answers-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<AnswersErrorsState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
