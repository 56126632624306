import React, { useState, useEffect, useCallback } from "react";
import { shallow } from "zustand/shallow";

import TextField from "@mui/material/TextField";

import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useErrorsStore } from "../../store/errorStore";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";

type TextInputType = {
  isRequired?: boolean;
  preguntaId: string;
};

const TextInput: React.FC<TextInputType> = ({ isRequired, preguntaId }) => {
  const [isEmptyError, setIsEmptyError] = useState<boolean>(false);
  const [texto, setTexto] = useState<string>("");

  const addEntityAnswer = useNewAnswersStore(
    useCallback((state) => state.addEntityAnswer, [])
  );

  const addEntityError = useErrorsStore(
    useCallback((state) => state.addEntityError, [])
  );

  const globalNextButtonClicked = useErrorsStore(
    useCallback((state) => state.globalNextButtonClicked, [])
  );

  const isGlobalNextButtonClicked = useErrorsStore(
    useCallback((state) => state.isGlobalNextButtonClicked, []),
    shallow
  );

  const textValue = useSurveyAnswer(preguntaId) || "";

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const handleChangeText = useCallback(
    (text: string) => {
      setTexto(text);
      if (!isPreviewSurvey) {
        addEntityAnswer(preguntaId, text);
      } else {
        addPreviewAnswer(preguntaId, text);
      }

      if (isRequired) {
        addEntityError(preguntaId, text.trim() === "");
        setIsEmptyError(text.trim() === "");
      }
    },
    [
      addEntityAnswer,
      addEntityError,
      addPreviewAnswer,
      isPreviewSurvey,
      isRequired,
      preguntaId,
    ]
  );

  useEffect(() => {
    setTexto(textValue || "");
    if (isRequired) {
      addEntityError(preguntaId, textValue.trim() === "");
    }
  }, [addEntityError, isRequired, preguntaId, textValue]);

  useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      addEntityError(preguntaId, texto.trim() === "");
      setIsEmptyError(texto.trim() === "");
      globalNextButtonClicked(false);
    }
  }, [
    addEntityError,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
    texto,
  ]);

  return (
    <React.Fragment>
      <TextField
        type="text"
        variant="outlined"
        margin="normal"
        onChange={(e) => handleChangeText(e.target.value)}
        value={texto}
        required={isRequired}
        focused={isEmptyError}
        error={isEmptyError}
        fullWidth
      />
      {isEmptyError && <ErrorMessage messageType="required" />}
    </React.Fragment>
  );
};

export default TextInput;
