import React, { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CircleValue from "./CircleValue";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";
import { useErrorsStore } from "../../store/errorStore";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

type NewRatingQuestionType = {
  isRequired?: boolean;
  leftLabel: string;
  options: any[];
  preguntaId: string;
  ratingSize: number;
  rightLabel: string;
};

const NewRatingQuestion: React.FC<NewRatingQuestionType> = ({
  isRequired,
  leftLabel,
  options,
  preguntaId,
  rightLabel,
}) => {
  const [isRequiredError, setIsRequiredError] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>("");

  const isGlobalNextButtonClicked = useErrorsStore(
    (state) => state.isGlobalNextButtonClicked,
    shallow
  );

  const globalNextButtonClicked = useErrorsStore(
    useCallback((state) => state.globalNextButtonClicked, [])
  );

  const { t } = useTranslation();

  const rankingValue = useSurveyAnswer(preguntaId);

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const addEntityAnswer = useNewAnswersStore(
    useCallback((state) => state.addEntityAnswer, [])
  );

  const addEntityError = useErrorsStore(
    useCallback((state) => state.addEntityError, [])
  );

  const setFinishSurvey = useNewAnswersStore(
    useCallback((state) => state.setFinishSurvey, [])
  );

  const handleChangeValue = React.useCallback(
    (newValue: number | null) => {
      if (newValue && options.length > 0) {
        if (!isPreviewSurvey) {
          addEntityAnswer(preguntaId, {
            [options[newValue - 1].Id]: options[newValue - 1].BGlobalValueText,
          });
        } else {
          addPreviewAnswer(preguntaId, {
            [options[newValue - 1].Id]: options[newValue - 1].BGlobalValueText,
          });
        }
        setSelected(options[newValue - 1].Id);

        if (isRequired) {
          addEntityError(preguntaId, false);
          setIsRequiredError(false);
        }
      }

      if (newValue) {
        setFinishSurvey(options[newValue - 1].BGlobalEndSurvey);
      } else {
        if (isRequired) {
          addEntityError(preguntaId, true);
        }
      }
    },
    [
      addEntityAnswer,
      addEntityError,
      addPreviewAnswer,
      isPreviewSurvey,
      isRequired,
      options,
      preguntaId,
      setFinishSurvey,
    ]
  );

  const showValue = () => {
    if (selected && options.length > 0) {
      return (
        options.indexOf(options.find((option) => option.Id === selected)) + 1
      );
    } else {
      return isRequired ? 0 : 1;
    }
  };

  const Rendervalue = (props: IconContainerProps) => {
    const { value, ...other } = props;
    // const Icon: any = MaterialIconsArray[value - 1];
    return (
      <CircleValue
        othersProps={{ ...other }}
        value={options[value - 1].BGlobalValueText}
      />
    );
  };

  const value = showValue();

  useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      if (!selected) {
        addEntityError(preguntaId, true);
        setIsRequiredError(true);
      } else {
        addEntityError(preguntaId, false);
        setIsRequiredError(false);
      }
      globalNextButtonClicked(false);
    }
  }, [
    addEntityError,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
    selected,
  ]);

  useEffect(() => {
    setSelected(rankingValue ? Object.keys(rankingValue)[0] : "");
    if (isRequired) {
      if (rankingValue) {
        addEntityError(preguntaId, false);
      } else {
        addEntityError(preguntaId, true);
      }
    }
  }, [addEntityError, isRequired, preguntaId, rankingValue]);

  useEffect(() => {
    if (!rankingValue && !isRequired) {
      addPreviewAnswer(preguntaId, {
        [options[0].Id]: options[0].BGlobalValueText,
      });
    }
  }, [addPreviewAnswer, isRequired, options, preguntaId, rankingValue]);

  return (
    <Grid item sm={6} md={12}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="flex-end"
        justifyContent="center"
      >
        <Box>
          <Typography fontSize="small" sx={{ color: "black" }}>
            {leftLabel || t("messages.ratingLow")}
          </Typography>
        </Box>

        <StyledRating
          name="highlight-selected-only"
          value={value}
          onChange={(event, newValue) => handleChangeValue(newValue)}
          max={options.length}
          IconContainerComponent={Rendervalue}
          sx={{
            width: "auto",
            margin: "4px",
          }}
          size="large"
          highlightSelectedOnly
        />

        <Box>
          <Typography fontSize="small" sx={{ color: "black" }}>
            {rightLabel || t("messages.ratingHigh")}
          </Typography>
        </Box>
      </Stack>
      {isRequiredError && <ErrorMessage messageType="required" />}
    </Grid>
  );
};

export default NewRatingQuestion;
