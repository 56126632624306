import * as React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";
import { styled } from "@mui/material";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  children: React.ReactElement;
}

const ScrollToTopStyled = styled(Box)(({ theme }) => ({
  zIndex: 11,
  position: "fixed",
  right: 16,
  bottom: 16,
}));

const ScrollToTop: React.FC<Props> = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector("#encuesta-name");

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  };

  return (
    <Zoom in={trigger}>
      <ScrollToTopStyled onClick={handleClick} role="presentation">
        {children}
      </ScrollToTopStyled>
    </Zoom>
  );
};

export default ScrollToTop;
