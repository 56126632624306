import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const fuentes = [
  {
    value: "Inter",
    label: "Inter",
  },
  {
    value: "Hanken Grotesk",
    label: "Hanken Grotesk",
  },
  {
    value: "DM Sans",
    label: "DM Sans",
  },
  {
    value: "Lora",
    label: "Lora",
  },
  {
    value: "Paytone One",
    label: "Paytone One",
  },
  {
    value: "Playfair Display",
    label: "Playfair Display",
  },
  {
    value: "Poppins",
    label: "Poppins",
  },
  {
    value: "Rubik",
    label: "Rubik",
  },
  {
    value: "Segoe UI",
    label: "Segoe UI",
  },
  {
    value: "Segoe UI Emoji",
    label: "Segoe UI Emoji",
  },
  {
    value: "Helvetica",
    label: "Helvetica",
  },
  {
    value: "Arial",
    label: "Arial",
  },
  {
    value: "serif",
    label: "serif",
  },
  {
    value: "sans-serif",
    label: "sans-serif",
  },
];

type MenuFuentesType = {
  fuente: string;
  handleSelectFuente: (fuente: string) => void;
};

const MenuFuentes: React.FC<MenuFuentesType> = ({
  fuente,
  handleSelectFuente,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickFuente = (fuente: string) => {
    handleSelectFuente(fuente);
    handleClose();
  };

  return (
    <React.Fragment>
      <Button
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {fuente}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {fuentes.map((fuente) => {
          return (
            <MenuItem
              key={fuente.value}
              onClick={() => handleClickFuente(fuente.value)}
            >
              {fuente.label}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

export default MenuFuentes;
