import * as React from "react";
import axios from "axios";
import { shallow } from "zustand/shallow";

import { ThemeProvider } from "@mui/material/styles";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Comentario from "../Comentario/Comentario";
import SurveyAnswer from "./SurveyAnswer";

import {
  createPreguntasTheme,
  createRespuestasTheme,
  createTitulosTheme,
} from "../../utils/theme";

import { useColorTituloStore } from "../../store/colorTituloStore";
import { useColorPreguntasStore } from "../../store/colorPreguntasStore";
import { useColorRespuestasStore } from "../../store/colorRespuestasStore";

import { useErrorsStore } from "../../store/errorStore";

import { BGlobalQuestionType, BGlobalSurveyAnswer } from "../../types";

type SurveyQuestionsType = {
  // questionId: string;
  questionSubtitle: string;
  questionTitle: string;
  requiredTitle: boolean;
  questions: BGlobalQuestionType[];
  condQuestions: BGlobalQuestionType[];
  handleRequiredQuestions: (value: boolean) => void;
  handleLoadingCmpt: React.Dispatch<React.SetStateAction<boolean>>;
  handleDisabledButton: React.Dispatch<React.SetStateAction<boolean>>;
};

const SurveyQuestions: React.FC<SurveyQuestionsType> = ({
  // questionId,
  questionSubtitle,
  questionTitle,
  requiredTitle,
  questions,
  condQuestions,
  handleRequiredQuestions,
  handleLoadingCmpt,
  handleDisabledButton,
}) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [loadedAnswers, setIsLoadedAnswers] = React.useState<boolean>(false);
  const [renderQuestions, setRenderQuestions] = React.useState<any[]>([]);
  const [todasPreguntas, setTodasPreguntas] = React.useState<any>();

  const { tituloFontColor, tituloFontFamily } = useColorTituloStore(
    ({ tituloFontColor, tituloFontFamily }) => ({
      tituloFontColor,
      tituloFontFamily,
    }),
    shallow
  );

  const { preguntaFontColor, preguntaFontFamily } = useColorPreguntasStore(
    ({ preguntaFontColor, preguntaFontFamily }) => ({
      preguntaFontColor,
      preguntaFontFamily,
    }),
    shallow
  );

  const { respuestaBackgroundColor, respuestaFontColor, respuestaFontFamily } =
    useColorRespuestasStore(
      ({
        respuestaBackgroundColor,
        respuestaFontColor,
        respuestaFontFamily,
      }) => ({
        respuestaBackgroundColor,
        respuestaFontColor,
        respuestaFontFamily,
      }),
      shallow
    );

  const { errorAnswers, isGlobalNextButtonClicked, requiredQuestions } =
    useErrorsStore(
      ({ errorAnswers, isGlobalNextButtonClicked, requiredQuestions }) => ({
        errorAnswers,
        isGlobalNextButtonClicked,
        requiredQuestions,
      }),
      shallow
    );

  const setRequiredQuestions = useErrorsStore(
    (state) => state.setRequiredQuestions,
    shallow
  );

  const titulosTheme = createTitulosTheme(tituloFontFamily, tituloFontColor);

  const preguntasTheme = createPreguntasTheme(
    preguntaFontFamily,
    preguntaFontColor
  );

  const respuestaTheme = createRespuestasTheme(
    respuestaBackgroundColor,
    respuestaFontFamily,
    respuestaFontColor
  );

  const areRequiredQuestionsAnswered = React.useCallback(() => {
    if (requiredQuestions > 0) {
      // Verificar el estado de validación de todas las preguntas obligatorias
      handleRequiredQuestions(
        Object.values(errorAnswers).every((errorAnswer) => !errorAnswer)
      );
    }
  }, [requiredQuestions, handleRequiredQuestions, errorAnswers]);

  React.useEffect(() => {
    if (questions.length > 0) {
      const preguntasRecord: Record<string, any> = questions.map((pregunta) => {
        if (pregunta.BGlobalAddComment) {
          console.log("PREGUNTA CON COMENTARIO", pregunta);
        }
        return {
          preguntaId: pregunta.Id,
          pregunta: pregunta.BGlobalQuestion,
          addComment: pregunta.BGlobalAddComment,
          isRequired: pregunta.BGlobalIsRequired,
          orderQuestion: pregunta.BGlobalOrderQuestion,
          showOrder: pregunta.BGlobalShowOrder,
          respuesta: {
            ...(pregunta.BGlobalAddValueNa && {
              addValueNA: pregunta.BGlobalAddValueNa,
            }),
            type: pregunta.BGlobalType.Description,
            answers: [],
          },
          ...(pregunta.BGlobalDependenceQuestionId !==
            "00000000-0000-0000-0000-000000000000" && {
            conditionalQuestionId: pregunta.BGlobalDependenceQuestionId,
          }),
          ...(pregunta.BGlobalType.Description === "ranking" && {
            leftLabel: pregunta.BGlobalLabel1,
            rightLabel: pregunta.BGlobalLabel2,
          }),
        };
      });

      const answerRequests = questions.map((pregunta) => {
        return axios
          .post<BGlobalSurveyAnswer>(
            `${process.env.REACT_APP_DO_URL}/getanswer`,
            {
              answerId: pregunta.Id,
            }
          )
          .then((res) => {
            const questionIndex = questions.findIndex(
              (q) => q.Id === pregunta.Id
            );

            preguntasRecord[questionIndex].respuesta.answers = res.data.value;
          })
          .catch((err) => {
            console.error(`${pregunta.Id} - answers - error `, err);
          });
      });

      Promise.all(answerRequests).then(() => {
        const preguntasMap: Record<string, any> = {
          preguntas: preguntasRecord,
        };
        console.log(preguntasRecord);

        console.log("preguntasMap", preguntasMap);

        const countRequiredQuestions = preguntasRecord.filter(
          (pregunta: any) => pregunta.isRequired === true
        );

        if (countRequiredQuestions) {
          if (countRequiredQuestions.length > 0) {
            setRequiredQuestions(countRequiredQuestions.length);
            handleRequiredQuestions(false);
          } else {
            setRequiredQuestions(0);
            handleRequiredQuestions(true);
          }
        } else {
          handleRequiredQuestions(false);
        }
        setIsLoadedAnswers(true);

        setTodasPreguntas(preguntasMap);
        setRenderQuestions(Object.keys(preguntasMap.preguntas));
        setIsLoading(false);
        handleLoadingCmpt(false);
        handleDisabledButton(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  React.useEffect(() => {
    if (isGlobalNextButtonClicked) {
      areRequiredQuestionsAnswered();
    }
  }, [areRequiredQuestionsAnswered, isGlobalNextButtonClicked]);

  return (
    <React.Fragment>
      {isLoading ? (
        <Grid container spacing={3}>
          <Grid
            container
            paddingBottom={2}
            paddingTop={4}
            justifyContent="center"
          >
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        loadedAnswers && (
          <>
            {requiredTitle && (
              <Box
                sx={{
                  display: "flex",
                  paddingY: 2,
                  justifyContent: "flex-start",
                }}
              >
                <Box sx={{ display: "block" }}>
                  <ThemeProvider theme={titulosTheme}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      {questionTitle}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      sx={{ display: "flex", justifyContent: "flex-start" }}
                    >
                      {questionSubtitle}
                    </Typography>
                  </ThemeProvider>
                </Box>
              </Box>
            )}
            {renderQuestions.length > 0 &&
              renderQuestions.map((p) => {
                return (
                  <React.Fragment key={todasPreguntas.preguntas[p].preguntaId}>
                    {!todasPreguntas.preguntas[p].conditionalQuestionId && (
                      <Box
                        sx={{
                          display: "flex",
                          paddingTop: 2,
                          placeContent: "flex-start",
                          placeItems: "center",
                        }}
                      >
                        <Box display="flex">
                          <ThemeProvider theme={preguntasTheme}>
                            {todasPreguntas.preguntas[p].showOrder && (
                              <Typography
                                variant="subtitle1"
                                component="div"
                                align="justify"
                              >
                                {todasPreguntas.preguntas[p].orderQuestion}
                              </Typography>
                            )}
                            <Typography
                              variant="subtitle1"
                              fontWeight="bold"
                              align="justify"
                              sx={{
                                marginLeft: todasPreguntas.preguntas[p]
                                  .showOrder
                                  ? 1
                                  : 0,
                              }}
                            >
                              {todasPreguntas.preguntas[p].pregunta}
                              {todasPreguntas.preguntas[p].isRequired && (
                                <Box
                                  component="span"
                                  sx={{ color: "error.main" }}
                                >
                                  *
                                </Box>
                              )}
                            </Typography>
                          </ThemeProvider>
                        </Box>
                      </Box>
                    )}
                    <ThemeProvider theme={respuestaTheme}>
                      {!todasPreguntas.preguntas[p].conditionalQuestionId && (
                        <React.Fragment>
                          <SurveyAnswer
                            answerType={
                              todasPreguntas.preguntas[p].respuesta.type
                            }
                            answers={
                              todasPreguntas.preguntas[p].respuesta.answers
                            }
                            conditionalQuestionId={
                              todasPreguntas.preguntas[p].conditionalQuestionId
                            }
                            {...(todasPreguntas.preguntas[p].respuesta.type ===
                              "list" && {
                              addValueNA:
                                todasPreguntas.preguntas[p].respuesta
                                  .addValueNA,
                            })}
                            {...(todasPreguntas.preguntas[p].respuesta.type ===
                              "ranking" && {
                              leftLabel: todasPreguntas.preguntas[p].leftLabel,
                              rightLabel:
                                todasPreguntas.preguntas[p].rightLabel,
                            })}
                            {...(todasPreguntas.preguntas[p].addComment && {
                              addComment:
                                todasPreguntas.preguntas[p].addComment,
                            })}
                            {...(todasPreguntas.preguntas[p].isRequired && {
                              isRequired:
                                todasPreguntas.preguntas[p].isRequired,
                            })}
                            condQuestions={condQuestions}
                            todasPreguntas={todasPreguntas}
                            preguntaId={todasPreguntas.preguntas[p].preguntaId}
                          />

                          {todasPreguntas.preguntas[p].addComment && (
                            <Comentario
                              preguntaId={
                                todasPreguntas.preguntas[p].preguntaId
                              }
                            />
                          )}
                        </React.Fragment>
                      )}
                    </ThemeProvider>

                    <Divider />
                  </React.Fragment>
                );
              })}
          </>
        )
      )}
    </React.Fragment>
  );
};

export default SurveyQuestions;
