import * as React from "react";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import DateQuestion from "../DateQuestion/DateQuestion";
import EmailInput from "../InputEmail/InputEmail";
import FileUploadQuestion from "../FileUploadQuestion/FileUploadQuestion";
import ListSelect from "./ListSelect";
import MatrizImage from "../MatrizImage/MatrizImage";
import MatrizSimple from "../MatrizSimple/MatrizSimple";
import MultipleInputText from "../MultipleInputText/MultipleInputText";
import NewCheckboxOptions from "../NewCheckboxOptions/NewCheckboxOptions";
import NewRatingQuestion from "../RatingQuestion/NewRatingQuestion";
import RadioQuestion from "../RadioQuestion/RadioQuestion";
import TableMatriz from "../NewMatrizQuestion/TableMatriz";
import TextInput from "../InputText/InputText";
import TextMultiQuestion from "../TextMultiQuestion/TextMultiQuestion";
import NumberInput from "../NumberInput/NumberInput";

type SurveyAnswerType = {
  addComment?: boolean;
  addValueNA?: boolean;
  answers: any[];
  answerText?: string;
  answerType: string;
  conditionalQuestionId: string;
  condQuestions?: any[];
  leftLabel?: string;
  preguntaId: string;
  rightLabel?: string;
  todasPreguntas?: any[];
  isRequired?: boolean;
};

const SurveyAnswer: React.FC<SurveyAnswerType> = ({
  addValueNA,
  answers,
  answerText,
  answerType,
  conditionalQuestionId,
  condQuestions,
  leftLabel,
  preguntaId,
  rightLabel,
  todasPreguntas,
  isRequired,
}) => {
  switch (answerType) {
    case "checkbox":
      return (
        <NewCheckboxOptions
          options={answers}
          preguntaId={preguntaId}
          isRequired={isRequired}
        />
      );

    case "date":
      return <DateQuestion preguntaId={preguntaId} isRequired={isRequired} />;

    case "email":
      return <EmailInput preguntaId={preguntaId} isRequired={isRequired} />;

    case "float":
    case "int":
      return !conditionalQuestionId ? (
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <NumberInput
            preguntaId={preguntaId}
            decimal={answerType === "float"}
            key={`${preguntaId}-numeric-input`}
            isRequired={isRequired}
          />
        </Box>
      ) : null;

    case "label":
      return null;

    case "list":
      return (
        <ListSelect
          list={answers}
          condQuestions={condQuestions}
          todasPreguntas={todasPreguntas}
          addValueNA={addValueNA}
          preguntaId={preguntaId}
          isRequired={isRequired}
        />
      );

    case "matriz":
      return (
        <TableMatriz
          preguntaId={preguntaId}
          matrixOptions={answers}
          isRequired={isRequired}
        />
      );

    case "matrizi":
      return (
        <MatrizImage
          matrixOptions={answers}
          preguntaId={preguntaId}
          isRequired={isRequired}
        />
      );

    case "matrizs":
      return (
        <MatrizSimple
          options={answers}
          preguntaId={preguntaId}
          isRequired={isRequired}
        />
      );

    case "mupload":
    case "upload":
      return (
        <FileUploadQuestion
          multiple={answerType === "mupload"}
          preguntaId={preguntaId}
          isRequired={isRequired}
        />
      );

    case "qtxtmulti":
      return (
        <MultipleInputText
          options={answers}
          answerText={answerText || ""}
          preguntaId={preguntaId}
          isRequired={isRequired}
        />
      );

    case "radiobutton":
      return (
        <RadioQuestion
          radioOptions={answers}
          preguntaId={preguntaId}
          isRequired={isRequired}
          condQuestions={condQuestions}
          todasPreguntas={todasPreguntas}
        />
      );

    case "ranking":
      return (
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <NewRatingQuestion
            options={answers}
            ratingSize={answers.length}
            leftLabel={leftLabel || ""}
            rightLabel={rightLabel || ""}
            preguntaId={preguntaId}
            isRequired={isRequired}
          />
        </Grid>
      );

    case "txt":
      return <TextInput preguntaId={preguntaId} isRequired={isRequired} />;

    case "txtmulti":
      return (
        <TextMultiQuestion preguntaId={preguntaId} isRequired={isRequired} />
      );

    default:
      return <Typography>{answerType}</Typography>;
  }
};

export default SurveyAnswer;
