import * as React from "react";
import { shallow } from "zustand/shallow";

import Avatar from "@mui/material/Avatar";

import { styled } from "@mui/material/styles";

import { useColorRespuestasStore } from "../../store/colorRespuestasStore";

const Circle = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  border: `1px solid ${theme.palette.info.main}`,
  color: "#ffffff",
  margin: "4px",
}));

type CircleValueType = {
  value: any;
  othersProps: any;
};

const CircleValue: React.FC<CircleValueType> = ({ value, othersProps }) => {
  const respuestaBackgroundColor = useColorRespuestasStore(
    (state) => state.respuestaBackgroundColor,
    shallow
  );

  return (
    <Circle
      className={othersProps.className}
      sx={{
        backgroundColor:
          othersProps.className.includes("MuiRating-iconFilled") &&
          respuestaBackgroundColor,
      }}
    >
      {value}
    </Circle>
  );
};

export default CircleValue;
