import * as React from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";

type ComentarioType = {
  preguntaId: string;
};

const Comentario: React.FC<ComentarioType> = ({ preguntaId }) => {
  const comentario: string = useSurveyAnswer(`${preguntaId}-comment`) || "";

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const addEntityAnswer = useNewAnswersStore((state) => state.addEntityAnswer);

  const { t } = useTranslation();

  const handleChangeComentarios = (text: string) => {
    if (!isPreviewSurvey) {
      addEntityAnswer(`${preguntaId}-comment`, text);
    } else {
      addPreviewAnswer(`${preguntaId}-comment`, text);
    }
  };

  React.useEffect(() => {
    if (!comentario) {
      handleChangeComentarios("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comentario]);

  return (
    <Box
      sx={{
        paddingTop: 4,
        display: "flex",
        justifyContent: "flex-start",
      }}
    >
      <TextField
        id="outlined-multiline-static"
        label={t("messages.comment")}
        placeholder={`${t("messages.comment")}...`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          handleChangeComentarios(e.target.value)
        }
        color="primary"
        value={comentario}
        type="text"
        rows={4}
        multiline
        focused
        fullWidth
      />
    </Box>
  );
};

export default Comentario;
