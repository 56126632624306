import * as React from "react";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import Answer from "./Answer";
import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useErrorsStore } from "../../store/errorStore";
import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";

import { BGlobalQuestionType } from "../../types";

type ListSelectType = {
  addValueNA?: boolean;
  condQuestions?: any[];
  isRequired?: boolean;
  list: any[];
  preguntaId: string;
  todasPreguntas: any;
};

const ListSelect: React.FC<ListSelectType> = ({
  addValueNA,
  condQuestions,
  isRequired,
  list,
  preguntaId,
  todasPreguntas,
}) => {
  const [showConditionalQuestion, setShowConditionalQuestion] =
    React.useState<boolean>(false);
  const [question, setQuestion] = React.useState<BGlobalQuestionType | null>();
  const [options, setOptions] = React.useState<any[]>([]);
  const [checkboxOptions, setCheckboxOptions] = React.useState<any[]>([]);
  const [selected, setSelected] = React.useState<any>();
  const [listError, setListError] = React.useState<boolean>(false);

  const selectedOption: Record<string, string> =
    useSurveyAnswer(preguntaId) || "";

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const addEntityAnswer = useNewAnswersStore(
    React.useCallback((state) => state.addEntityAnswer, [])
  );

  const setFinishSurvey = useNewAnswersStore(
    React.useCallback((state) => state.setFinishSurvey, [])
  );

  const isGlobalNextButtonClicked = useErrorsStore(
    (state) => state.isGlobalNextButtonClicked,
    shallow
  );

  const globalNextButtonClicked = useErrorsStore(
    React.useCallback((state) => state.globalNextButtonClicked, [])
  );

  const addEntityError = useErrorsStore(
    React.useCallback((state) => state.addEntityError, [])
  );

  const handleChangeSelectedOption = (optionId: string) => {
    const findedOption = list.find((option) => option.Id === optionId);
    if (findedOption) {
      if (!isPreviewSurvey) {
        addEntityAnswer(preguntaId, {
          [findedOption.Id]: findedOption.BGlobalValueText,
        });

        if (isRequired) {
          addEntityError(preguntaId, false);
          setListError(false);
        }
      } else {
        addPreviewAnswer(preguntaId, {
          [findedOption.Id]: findedOption.BGlobalValueText,
        });
      }
      setSelected({
        [findedOption.Id]: findedOption.BGlobalValueText,
      });
    } else {
      if (!isPreviewSurvey) {
        addEntityAnswer(preguntaId, { "": "" });

        if (isRequired) {
          addEntityError(preguntaId, true);
          setListError(true);
        }
      } else {
        addPreviewAnswer(preguntaId, { "": "" });
      }
    }

    if (optionId) {
      if (list) {
        const finishSurvey = list.find(
          (opt) => opt.Id === optionId && opt.BGlobalEndSurvey
        );

        setFinishSurvey(finishSurvey);
      }

      if (condQuestions) {
        const encontrado = condQuestions.find(
          (pregunta) => pregunta.BGlobalDependenceResponseId === optionId
        );

        if (encontrado) {
          setQuestion(encontrado);
          const condQuestion: any = Object.values(
            todasPreguntas.preguntas
          ).find(
            (pregunta: any) =>
              pregunta.conditionalQuestionId ===
              encontrado.BGlobalDependenceQuestionId
          );

          if (condQuestion && condQuestion.respuesta.type === "checkbox") {
            setCheckboxOptions(condQuestion.respuesta.answers);
          }

          setShowConditionalQuestion(true);
        } else {
          setQuestion(null);
          setShowConditionalQuestion(false);
        }
      }
    } else {
      addEntityError(preguntaId, true);
      setListError(true);
    }
  };

  React.useEffect(() => {
    if (list) {
      if (addValueNA) {
        setOptions([...list, { BGlobalValueText: "N/A", Id: "no-asigned" }]);
      } else {
        setOptions(list);
      }

      if (selectedOption) {
        handleChangeSelectedOption(Object.keys(selectedOption)[0]);
      }

      if (!selectedOption) {
        for (let index = 0; index < list.length; index++) {
          const element = list[index];
          if (element.BGlobalIsDefault) {
            handleChangeSelectedOption(element.Id);
            break;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  React.useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      if (selected && Object.values(selected)[0]) {
        addEntityError(preguntaId, false);
        setListError(false);
      } else {
        addEntityError(preguntaId, true);
        setListError(true);
      }
      globalNextButtonClicked(false);
    }
  }, [
    addEntityError,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
    selected,
  ]);

  React.useEffect(() => {
    setSelected(selectedOption || "");
    if (isRequired) {
      if (selectedOption && Object.values(selectedOption)[0]) {
        addEntityError(preguntaId, false);
      } else {
        addEntityError(preguntaId, true);
      }
    }
  }, [addEntityError, isRequired, preguntaId, selectedOption]);

  return (
    <Box>
      {options.length > 0 && (
        <React.Fragment>
          <TextField
            id="list-question-select"
            select
            value={Object.keys(selected)[0] || ""}
            onChange={(e) => handleChangeSelectedOption(e.target.value)}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              width: "40%",
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.Id} value={option.Id}>
                {option.BGlobalValueText}
              </MenuItem>
            ))}
          </TextField>
          {listError && <ErrorMessage messageType="required" />}
        </React.Fragment>
      )}

      {showConditionalQuestion && question && (
        <Answer
          question={question}
          checkboxOptions={checkboxOptions}
          preguntaId={question.Id}
          isRequired={question.BGlobalIsRequired}
        />
      )}
    </Box>
  );
};

export default ListSelect;
