import React, { useCallback, useEffect, useState } from "react";
import { shallow } from "zustand/shallow";

import TextField from "@mui/material/TextField";

import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useErrorsStore } from "../../store/errorStore";
import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";

type TextMultiQuestionType = {
  isRequired?: boolean;
  preguntaId: string;
};

const TextMultiQuestion: React.FC<TextMultiQuestionType> = ({
  isRequired,
  preguntaId,
}) => {
  const [valueText, setValueText] = useState<string>("");
  const [isEmptyError, setIsEmptyError] = useState<boolean>(false);

  const addEntityAnswer = useNewAnswersStore((state) => state.addEntityAnswer);
  const addEntityError = useErrorsStore((state) => state.addEntityError);
  const globalNextButtonClicked = useErrorsStore(
    (state) => state.globalNextButtonClicked
  );
  const isGlobalNextButtonClicked = useErrorsStore(
    (state) => state.isGlobalNextButtonClicked,
    shallow
  );

  const text = useSurveyAnswer(preguntaId) || "";

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const handleChangeText = useCallback(
    (texto: string) => {
      if (!isPreviewSurvey) {
        addEntityAnswer(preguntaId, texto);
      } else {
        addPreviewAnswer(preguntaId, texto);
      }
      setValueText(texto);

      if (isRequired) {
        setIsEmptyError(texto.trim() === "");
        addEntityError(preguntaId, texto.trim() === "");
      }
    },
    [
      addEntityAnswer,
      addEntityError,
      addPreviewAnswer,
      isPreviewSurvey,
      isRequired,
      preguntaId,
    ]
  );

  useEffect(() => {
    setValueText(text || "");
  }, [text]);

  useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      addEntityError(preguntaId, text.trim() === "");
      setIsEmptyError(text.trim() === "");
      globalNextButtonClicked(false);
    }
  }, [
    addEntityError,
    globalNextButtonClicked,
    handleChangeText,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
    text,
  ]);

  return (
    <React.Fragment>
      <TextField
        id="filled-multiline-flexible"
        multiline
        rows={4}
        variant="filled"
        value={valueText}
        onChange={(e) => handleChangeText(e.target.value)}
        required={isRequired}
        focused={isEmptyError}
        error={isEmptyError}
        fullWidth
        inputProps={{ style: { resize: "both" } }}
      />

      {isEmptyError && <ErrorMessage messageType="required" />}
    </React.Fragment>
  );
};

export default TextMultiQuestion;
