import * as React from "react";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

type CustomProgressBarsType = {
  totalSteps: number;
  actualStep: number;
};

const CustomProgressBars: React.FC<CustomProgressBarsType> = ({
  actualStep,
  totalSteps,
}) => {
  const barProgress =
    actualStep === 0 && totalSteps === 0
      ? 0
      : Number(((actualStep / totalSteps) * 100).toFixed(3));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <BorderLinearProgress
        variant="determinate"
        value={barProgress}
        sx={{
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: barProgress >= 95 ? 0 : 5,
          },
        }}
      />
    </Box>
  );
};

export default CustomProgressBars;
