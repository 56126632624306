import * as React from "react";
import { shallow } from "zustand/shallow";

import { ThemeProvider } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import DateQuestion from "../DateQuestion/DateQuestion";
import FileUploadQuestion from "../FileUploadQuestion/FileUploadQuestion";
import NewCheckboxOptions from "../NewCheckboxOptions/NewCheckboxOptions";
import NumberInput from "../NumberInput/NumberInput";
import RadioQuestion from "../RadioQuestion/RadioQuestion";

import { useColorPreguntasStore } from "../../store/colorPreguntasStore";
import { useColorRespuestasStore } from "../../store/colorRespuestasStore";

import { createPreguntasTheme, createRespuestasTheme } from "../../utils/theme";

import { BGlobalQuestionType } from "../../types";
import TextInput from "../InputText/InputText";

type CondAnswerType = {
  checkboxOptions: any[];
  preguntaId: string;
  question: BGlobalQuestionType;
  isRequired?: boolean;
  condQuestions?: any;
  condAnswers?: any;
  todasPreguntas?: any;
};

const Answer: React.FC<CondAnswerType> = ({
  checkboxOptions,
  preguntaId,
  question,
  condAnswers,
  isRequired,
  condQuestions,
  todasPreguntas,
}) => {
  const renderAnswerType = () => {
    const answerType = question.BGlobalType.Description;

    switch (answerType) {
      case "checkbox":
        return (
          <NewCheckboxOptions
            options={checkboxOptions}
            preguntaId={preguntaId}
          />
        );

      case "date":
        return <DateQuestion preguntaId={preguntaId} />;

      case "float":
      case "int":
        return (
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <NumberInput
              preguntaId={preguntaId}
              decimal={answerType === "float"}
              key={`${preguntaId}-numeric-input`}
            />
          </Box>
        );

      case "mupload":
      case "upload":
        return (
          <Box>
            <FileUploadQuestion
              multiple={answerType === "mupload"}
              preguntaId={preguntaId}
            />
          </Box>
        );

      case "radiobutton":
        return (
          <RadioQuestion
            radioOptions={condAnswers.respuesta.answers}
            preguntaId={preguntaId}
            isRequired={isRequired}
            condQuestions={condQuestions}
            todasPreguntas={todasPreguntas}
            isConditionalQuestion={false}
          />
        );

      case "txtmulti":
        return (
          <Box>
            <TextField
              id="filled-multiline-flexible"
              multiline
              rows={4}
              variant="filled"
              fullWidth
              inputProps={{ style: { resize: "both" } }}
            />
          </Box>
        );

      case "txt":
        return <TextInput preguntaId={preguntaId} isRequired={isRequired} />;

      default:
        return <Typography>{answerType}</Typography>;
    }
  };

  const { preguntaFontColor, preguntaFontFamily } = useColorPreguntasStore(
    ({ preguntaFontColor, preguntaFontFamily }) => ({
      preguntaFontColor,
      preguntaFontFamily,
    }),
    shallow
  );

  const { respuestaBackgroundColor, respuestaFontColor, respuestaFontFamily } =
    useColorRespuestasStore(
      ({
        respuestaBackgroundColor,
        respuestaFontColor,
        respuestaFontFamily,
      }) => ({
        respuestaBackgroundColor,
        respuestaFontColor,
        respuestaFontFamily,
      }),
      shallow
    );

  const preguntasTheme = createPreguntasTheme(
    preguntaFontFamily,
    preguntaFontColor
  );

  const respuestaTheme = createRespuestasTheme(
    respuestaBackgroundColor,
    respuestaFontFamily,
    respuestaFontColor
  );

  return (
    <React.Fragment>
      <ThemeProvider theme={preguntasTheme}>
        <Box
          sx={{
            display: "flex",
            paddingTop: 2,
            placeContent: "flex-start",
            placeItems: "center",
          }}
        >
          <Box display="block">
            <Typography variant="subtitle1" fontWeight="bold">
              {question.BGlobalQuestion}
              {isRequired && (
                <Box component="span" sx={{ color: "error.main" }}>
                  *
                </Box>
              )}
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>

      <ThemeProvider theme={respuestaTheme}>{renderAnswerType()}</ThemeProvider>

      <Divider />
    </React.Fragment>
  );
};

export default Answer;
