import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
/* import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel"; */
import Grid from "@mui/material/Grid";
/* import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select"; */
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { BGlobalContact, BGlobalEntityType } from "../../types";
import { useNewAnswersStore } from "../../store/newAnswersStore";
import { shallow } from "zustand/shallow";
import AsynchronusSelect from "../AsynchronusSelect/AsynchronusSelect";

/* const emptyId = "00000000-0000-0000-0000-000000000000";

type StateType =
  | "Id"
  | "Name"
  | "Email"
  | "Phone"
  | "MobilePhone"
  | "JobTitle"
  | "AccountId"
  | "Address"
  | "Zip"
  | "CityId"
  | "RegionId"
  | "CountryId"
  | "AddressTypeId"; */

const UpdateDataForm = () => {
  const [contactId, setContactId] = useState<string>("");
  const [canUpdateData, setCanUpdateData] = useState<boolean>(true);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [reloadForm, setReloadForm] = useState<boolean>(false);
  const [sendingForm, setSendingForm] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    Id: "",
    Name: "",
    Email: "",
    Phone: "",
    MobilePhone: "",
    JobTitle: "",
    AccountId: "",
    Address: "",
    Zip: "",
    CityId: "",
    RegionId: "",
    CountryId: "",
    AddressTypeId: "",
    AccountName: "",
    AddressName: "",
    CityName: "",
    CountryName: "",
    RegionName: "",
  });

  const [originalFormData, setOriginalFormData] = useState<any>({
    Id: "",
    Name: "",
    Email: "",
    Phone: "",
    MobilePhone: "",
    JobTitle: "",
    AccountId: "",
    Address: "",
    Zip: "",
    CityId: "",
    RegionId: "",
    CountryId: "",
    AddressTypeId: "",
    AccountName: "",
    AddressName: "",
    CityName: "",
    CountryName: "",
    RegionName: "",
  });

  const entityId = useNewAnswersStore((state) => state.entityId, shallow);

  const { t } = useTranslation();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: value,
    }));
  };

  /* const handleChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }; */

  const getContact = useCallback(async (contactId: string) => {
    setIsLoading(true);
    axios
      .post<BGlobalContact>(`${process.env.REACT_APP_DO_URL}/getcontact`, {
        contactId,
      })
      .then((res) => {
        setFormData(res.data);
        setOriginalFormData(res.data);
        // Verificar si las propiedades de lookup tienen valores
        if (
          res.data.AccountId ||
          res.data.CityId ||
          res.data.RegionId ||
          res.data.CountryId ||
          res.data.AddressTypeId
        ) {
          // Encadenar solicitudes adicionales en función de las propiedades de lookup
          const promiseArray = [];

          promiseArray.push(
            axios.post(`${process.env.REACT_APP_DO_URL}/getcontact`, {
              contactId,
              AccountId: res.data.AccountId,
            })
          );
          promiseArray.push(
            axios.post(`${process.env.REACT_APP_DO_URL}/getcontact`, {
              contactId,
              CityId: res.data.CityId,
            })
          );
          promiseArray.push(
            axios.post(`${process.env.REACT_APP_DO_URL}/getcontact`, {
              contactId,
              RegionId: res.data.RegionId,
            })
          );
          promiseArray.push(
            axios.post(`${process.env.REACT_APP_DO_URL}/getcontact`, {
              contactId,
              CountryId: res.data.CountryId,
            })
          );
          promiseArray.push(
            axios.post(`${process.env.REACT_APP_DO_URL}/getcontact`, {
              contactId,
              AddressTypeId: res.data.AddressTypeId,
            })
          );

          /* if (res.data.AccountId === emptyId) {
            promiseArray.push(
              axios.post(`${process.env.REACT_APP_DO_URL}/getcontact`, {
                contactId,
                AccountId: res.data.AccountId,
              })
            );
          } else if (res.data.CityId === emptyId) {
            promiseArray.push(
              axios.post(`${process.env.REACT_APP_DO_URL}/getcontact`, {
                contactId,
                CityId: res.data.CityId,
              })
            );
          } else if (res.data.RegionId === emptyId) {
            promiseArray.push(
              axios.post(`${process.env.REACT_APP_DO_URL}/getcontact`, {
                contactId,
                RegionId: res.data.RegionId,
              })
            );
          } else if (res.data.CountryId === emptyId) {
            promiseArray.push(
              axios.post(`${process.env.REACT_APP_DO_URL}/getcontact`, {
                contactId,
                CountryId: res.data.CountryId,
              })
            );
          } else if (res.data.AddressTypeId === emptyId) {
            promiseArray.push(
              axios.post(`${process.env.REACT_APP_DO_URL}/getcontact`, {
                contactId,
                AddressTypeId: res.data.AddressTypeId,
              })
            );
          } */
          // Repetir el proceso para otras propiedades de lookup
          // ...

          // Realizar la última solicitud y manejar el resultado
          return Promise.all(promiseArray).then((result) => {
            for (const iterator of result) {
              console.log(
                "PropertyName: ",
                iterator.data["@odata.context"].slice(
                  iterator.data["@odata.context"].indexOf("#") + 1,
                  -8
                )
              );

              if (iterator.data.isAccount) {
                setFormData((prevData: any) => ({
                  ...prevData,
                  AccountName: iterator.data.Name,
                }));
                setOriginalFormData((prevData: any) => ({
                  ...prevData,
                  AccountName: iterator.data.Name,
                }));
              } else if (iterator.data.isCity) {
                setFormData((prevData: any) => ({
                  ...prevData,
                  CityName: iterator.data.Name,
                }));
                setOriginalFormData((prevData: any) => ({
                  ...prevData,
                  CityName: iterator.data.Name,
                }));
              } else if (iterator.data.isRegion) {
                setFormData((prevData: any) => ({
                  ...prevData,
                  RegionName: iterator.data.Name,
                }));
                setOriginalFormData((prevData: any) => ({
                  ...prevData,
                  RegionName: iterator.data.Name,
                }));
              } else if (iterator.data.isCountry) {
                setFormData((prevData: any) => ({
                  ...prevData,
                  CountryName: iterator.data.Name,
                }));
                setOriginalFormData((prevData: any) => ({
                  ...prevData,
                  CountryName: iterator.data.Name,
                }));
              } else if (iterator.data.isAddressType) {
                setFormData((prevData: any) => ({
                  ...prevData,
                  AddressName: iterator.data.Name,
                }));
                setOriginalFormData((prevData: any) => ({
                  ...prevData,
                  AddressName: iterator.data.Name,
                }));
              }

              /* const propertyName = iterator.data["@odata.context"].slice(
                iterator.data["@odata.context"].indexOf("#") + 1,
                -8
              ); */
            }
            setIsLoading(false);
            setReloadForm(false);
            setSendingForm(false);
          });
        }
      })
      .catch((e) => {
        console.log("CONTACT DATA ERROR", e);
        setCanUpdateData(false);
        setContactId("");
        showCantUpdateDataAlert();
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChangeSelect = (
    name: string,
    value: any,
    nameId: string,
    valueId: string
  ) => {
    console.log("handleOnChangeSelect", name, value, nameId, valueId);
    if (nameId === "CountryId") {
      setFormData((prevData: any) => ({
        ...prevData,
        CountryName: value,
        CountryId: valueId,
        CityId: "",
        CityName: "",
        RegionId: "",
        RegionName: "",
      }));
    } else if (nameId === "RegionId") {
      setFormData((prevData: any) => ({
        ...prevData,
        CityId: "",
        CityName: "",
        RegionId: valueId,
        RegionName: value,
      }));
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: value,
        [nameId]: valueId,
      }));
    }
  };
  const getContactId = useCallback(() => {
    setIsLoading(true);
    axios
      .post<BGlobalEntityType>(`${process.env.REACT_APP_DO_URL}/getentity`, {
        entityId,
      })
      .then((res) => {
        axios
          .post(`${process.env.REACT_APP_DO_URL}/validatekey`, {
            surveyId: res.data.BGlobalSurveyId,
          })
          .then((resVK) => {
            if (resVK.data.isValidKey) {
              setCanUpdateData(true);
              setContactId(res.data.BGlobalContactId);
            } else {
              setCanUpdateData(false);
              setIsLoading(false);
              setContactId("");
              showCantUpdateDataAlert();
            }
          })
          .catch((e) => {
            console.error("VALIDATE KEY-ERROR", e);
            setCanUpdateData(false);
            setIsLoading(false);
            setContactId("");
            showCantUpdateDataAlert();
          });
      })
      .catch((err) => {
        console.error("ERROR", err);
        setCanUpdateData(false);
        setIsLoading(false);
        setContactId("");
        showCantUpdateDataAlert();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityId]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    setSendingForm(true);
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_DO_URL}/updatecontact`, {
        formData,
      })
      .then((res) => {
        console.log("conctact updated", res.data);
        showUpdatedDataAlert();
        setReloadForm(true);
      })
      .catch((err) => {
        console.error("ERR", err);
        showDismissableCantUpdateDataAlert();
        setSendingForm(false);
      });
    // Aquí puedes realizar la lógica para enviar los datos del formulario
  };

  const showCantUpdateDataAlert = () => {
    Swal.fire({
      icon: "error",
      title: t<string>("errorMessage.dataCantUpdate"),
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  };

  const showDismissableCantUpdateDataAlert = () => {
    Swal.fire({
      icon: "error",
      title: t<string>("errorMessage.dataCantUpdate"),
      showConfirmButton: false,
      showCloseButton: true,
    });
  };

  const showUpdatedDataAlert = () => {
    Swal.fire({
      icon: "success",
      title: t<string>("messages.dataUpdated"),
      showConfirmButton: false,
    });
  };

  useEffect(() => {
    const isFormDirty = Object.keys(formData).some(
      (key) => formData[key] !== originalFormData[key]
    );
    setIsFormDirty(isFormDirty);
  }, [formData, originalFormData]);

  useEffect(() => {
    if (contactId) {
      getContact(contactId);
    }
  }, [contactId, getContact]);

  useEffect(() => {
    if (reloadForm) {
      getContact(contactId);
    }
  }, [contactId, getContact, reloadForm]);

  useEffect(() => {
    getContactId();
  }, [getContactId]);

  return (
    <Box>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="absolute"
          elevation={0}
          color="primary"
          sx={{ position: "relative" }}
        >
          <Toolbar>
            <Typography color="primary">BGlobal Solutions</Typography>

            <Box color="primary.main" sx={{ flexGrow: 1 }}>
              0
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        canUpdateData && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: 600,
                mx: "auto",
                p: 2,
                border: "2px solid  #000000",
                borderRadius: "12px",
                boxShadow: 1,
              }}
            >
              <Typography variant="h4" align="center" mb={2}>
                {t("messages.updateYourData")}
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={t("messages.fullName")}
                      name="Name"
                      value={formData.Name}
                      onChange={handleChange}
                      margin="normal"
                      // required
                    />
                    <TextField
                      fullWidth
                      label={t("messages.phoneNumber")}
                      name="Phone"
                      value={formData.Phone}
                      onChange={handleChange}
                      margin="normal"
                      // required
                    />
                    <TextField
                      fullWidth
                      label={t("messages.mobile")}
                      name="MobilePhone"
                      value={formData.MobilePhone}
                      onChange={handleChange}
                      margin="normal"
                      // required
                    />
                    <TextField
                      fullWidth
                      label={t("messages.email")}
                      name="Email"
                      value={formData.Email}
                      onChange={handleChange}
                      margin="normal"
                      // required
                    />
                    <AsynchronusSelect
                      onChangeCountry={handleOnChangeSelect}
                      value={formData}
                      selectType="Country"
                      label={t("messages.country")}
                      name="CountryName"
                      nameId="CountryId"
                      required
                    />
                    <AsynchronusSelect
                      onChangeCountry={handleOnChangeSelect}
                      value={formData}
                      selectType="City"
                      label={t("messages.city")}
                      name="CityName"
                      nameId="CityId"
                      // disabled={formData.RegionId === ""}
                      // required
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {/* ... Campos anteriores ... */}
                    <TextField
                      fullWidth
                      label={t("messages.address")}
                      name="Address"
                      value={formData.Address}
                      onChange={handleChange}
                      margin="normal"
                      // required
                    />
                    <TextField
                      fullWidth
                      label={t("messages.zipPostalCode")}
                      name="Zip"
                      value={formData.Zip}
                      onChange={handleChange}
                      margin="normal"
                      // required
                    />
                    <TextField
                      fullWidth
                      label={t("messages.jobTitle")}
                      name="JobTitle"
                      value={formData.AccountName}
                      onChange={handleChange}
                      margin="normal"
                      // required
                    />
                    {/* <TextField
                      fullWidth
                      label={t("messages.account")}
                      name="AccountName"
                      value={formData.AccountName}
                      onChange={handleChange}
                      margin="normal"
                      // required
                    /> */}
                    <AsynchronusSelect
                      onChangeCountry={handleOnChangeSelect}
                      value={formData}
                      selectType="Account"
                      label={t("messages.account")}
                      name="AccountName"
                      nameId="AccountId"
                      // disabled={formData.RegionId === ""}
                      // required
                    />
                    <AsynchronusSelect
                      onChangeCountry={handleOnChangeSelect}
                      value={formData}
                      selectType="Region"
                      label={t("messages.state")}
                      name="RegionName"
                      nameId="RegionId"
                      // disabled={formData.CountryId === ""}
                      // required
                    />
                    <AsynchronusSelect
                      onChangeCountry={handleOnChangeSelect}
                      value={formData}
                      selectType="AddressType"
                      label={t("messages.addressType")}
                      name="AddressName"
                      nameId="AddressTypeId"
                    />
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  disabled={!isFormDirty || sendingForm}
                  type="submit"
                  color="primary"
                  variant="contained"
                  sx={{ mt: 2 }}
                >
                  {t("button.send")}
                </Button>
              </form>
            </Box>
          </Box>
        )
      )}
    </Box>
  );
};

export default UpdateDataForm;
