import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import ConfigurationDrawer from "./components/Survey/ConfigurationDrawer";
import FloatButton from "./components/FloatButton/FloatButton";
import Survey from "./components/Survey/Survey";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import UpdateDataForm from "./components/UpdateDataForm/UpdateDataForm";

import { MaterialIcons } from "./icons/Material/MaterialIcons";

import { createEncuestaTheme } from "./utils/theme";

import { useColorEncuestaStore } from "./store/colorEncuestaStore";
import { useErrorsStore } from "./store/errorStore";
import { useNewAnswersStore } from "./store/newAnswersStore";
import { useNewPreviewAnswersStore } from "./store/newPreviewAnswersStore";

import {
  BGlobalEntityType,
  BGlobalLanguageType,
  BGlogalSurveyType,
} from "./types";

import "./App.css";

function App() {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [surveyIsComplete, setSurveyIsComplete] =
    React.useState<boolean>(false);
  const [openSettingsDrawer, setOpenSettingsDrawer] =
    React.useState<boolean>(false);
  const [isPreview, setIsPreview] = React.useState<boolean>(false);
  const [isSendAnswers, setIsSendAnswers] = React.useState<boolean>(false);
  const [isValidKey, setIsValidKey] = React.useState<boolean>(true);
  const [isUpdateData, setIsUpdateData] = React.useState<boolean>(false);
  const [survey, setSurvey] = React.useState<BGlogalSurveyType>();
  const [entidadId, setEntidadId] = React.useState<string>("");

  const { encuestaBackgroundColor, encuestaFontColor, encuestaFontFamily } =
    useColorEncuestaStore(
      ({ encuestaBackgroundColor, encuestaFontColor, encuestaFontFamily }) => ({
        encuestaBackgroundColor,
        encuestaFontColor,
        encuestaFontFamily,
      }),
      shallow
    );

  const setSurveyId = useNewAnswersStore((state) => state.setSurveyId);

  const setPreviewSurveyId = useNewPreviewAnswersStore(
    (state) => state.setPreviewSurveyId
  );

  const resetPreviewSurveyAnswers = useNewPreviewAnswersStore(
    (state) => state.resetPreviewSurveyAnswers
  );

  const setEntityId = useNewAnswersStore((state) => state.setEntityId);

  const setIsPreviewSurvey = useNewAnswersStore(
    (state) => state.setIsPreviewSurvey
  );

  const setSurveyPreviewCurrentPage = useNewAnswersStore(
    (state) => state.setSurveyPreviewCurrentPage
  );

  const setSurveyUpdateData = useNewAnswersStore(
    (state) => state.setSurveyUpdateData
  );

  const resetSurveyAnswers = useNewAnswersStore(
    (state) => state.resetSurveyAnswers
  );

  const setErrorSurveyId = useErrorsStore((state) => state.setErrorSurveyId);

  const setErrorEntityId = useErrorsStore((state) => state.setErrorEntityId);

  const resetErrorSurveyAnswers = useErrorsStore(
    (state) => state.resetErrorSurveyAnswers
  );

  const { t } = useTranslation();

  const encuestaTheme = createEncuestaTheme(
    encuestaBackgroundColor,
    encuestaFontFamily,
    encuestaFontColor
  );

  const handleClickSettingsButton = () => {
    setOpenSettingsDrawer(true);
  };

  const handleCloseSettingsDrawer = () => {
    setOpenSettingsDrawer(false);
  };

  const showCompletedAlert = () => {
    Swal.fire({
      icon: "info",
      title: t<string>("messages.surveyCompleted"),
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  };

  const showInvalidKeyAlert = () => {
    Swal.fire({
      icon: "error",
      title: t<string>("errorMessage.invalidApiKey"),
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  };

  React.useEffect(() => {
    console.log(window.location.pathname);
    setIsLoading(true);
    if (window.location.pathname === "/updatedata") {
      setIsUpdateData(true);
      setIsLoading(false);
    } else if (
      window.location.pathname === "/SurveyView" &&
      window.location.search.toLowerCase().slice(1, 10) === "surveyid="
    ) {
      axios
        .post<BGlogalSurveyType>(`${process.env.REACT_APP_DO_URL}/getsurvey`, {
          surveyId: window.location.search.slice(10),
        })
        .then((res) => {
          console.log("survey data ", res.data);
          setIsUpdateData(false);
          axios
            .post<BGlobalLanguageType>(
              `${process.env.REACT_APP_DO_URL}/getlanguage`,
              {
                surveyId: window.location.search.slice(10),
              }
            )
            .then((resLng) => {
              console.log("LANGUAGE", resLng.data.BGlobalCode);
              i18n.changeLanguage(resLng.data.BGlobalCode);

              axios
                .post(`${process.env.REACT_APP_DO_URL}/validatekey`, {
                  surveyId: window.location.search.slice(10),
                })
                .then((resVK) => {
                  console.log("VALIDATE KEY", resVK.data.isValidKey);
                  if (resVK.data.isValidKey) {
                    setSurvey(res.data);
                    setSurveyId(res.data.Id);
                    setPreviewSurveyId(res.data.Id);
                    setSurveyPreviewCurrentPage(res.data.Id, 0);
                    setErrorSurveyId(res.data.Id);
                    setIsPreview(true);
                    setIsSendAnswers(false);
                    setIsPreviewSurvey(true);
                    setIsValidKey(true);
                  } else {
                    setIsValidKey(false);
                    showInvalidKeyAlert();
                  }
                  setIsLoading(false);
                })
                .catch((errVk) => {
                  console.error("VALIDATE KEY-ERROR", errVk);
                  setIsLoading(false);
                });
            })
            .catch((errLng) => {
              console.error("LANGUAGE-ERROR", errLng);
            });
        })
        .catch((e) => {
          setSurveyIsComplete(true);

          showInvalidKeyAlert();
          console.error("getsurvey - error", e);
          setIsLoading(false);
        });
    } else if (
      window.location.pathname === "/Survey" &&
      window.location.search.toLowerCase().slice(1, 16) === "surveyentityid="
    ) {
      axios
        .post<BGlobalEntityType>(`${process.env.REACT_APP_DO_URL}/getentity`, {
          entityId: window.location.search.slice(16),
        })
        .then((res) => {
          console.log("entity data ", res.data);
          setIsUpdateData(false);
          axios
            .post<BGlobalLanguageType>(
              `${process.env.REACT_APP_DO_URL}/getlanguage`,
              {
                surveyId: res.data.BGlobalSurveyId,
              }
            )
            .then((resLng) => {
              i18n.changeLanguage(resLng.data.BGlobalCode);

              if (res.data.BGlobalIsCompleted) {
                setSurveyIsComplete(true);
                resetSurveyAnswers();
                resetErrorSurveyAnswers();
                showCompletedAlert();
              } else {
                setSurveyIsComplete(false);
                setEntityId(res.data.Id);
                setEntidadId(res.data.Id);
                setErrorEntityId(res.data.Id);

                axios
                  .post(`${process.env.REACT_APP_DO_URL}/validatekey`, {
                    surveyId: res.data.BGlobalSurveyId,
                  })
                  .then((resVK) => {
                    console.log("VALIADTE KEY", resVK.data.isValidKey);
                    if (resVK.data.isValidKey) {
                      axios
                        .post<BGlogalSurveyType>(
                          `${process.env.REACT_APP_DO_URL}/getsurvey`,
                          {
                            surveyId: res.data.BGlobalSurveyId,
                          }
                        )
                        .then((resSurvey) => {
                          console.log("survey data ", resSurvey.data);
                          setSurvey(resSurvey.data);
                          setSurveyId(resSurvey.data.Id);
                          setErrorSurveyId(resSurvey.data.Id);
                          setSurveyUpdateData(resSurvey.data.BGlobalUpdateData);
                          // setSurveyUpdateData(true);
                          setIsPreview(false);
                          setIsValidKey(true);
                          setIsSendAnswers(true);
                          setIsPreviewSurvey(false);
                          setIsLoading(false);
                        })
                        .catch((e) => {
                          setSurveyIsComplete(true);

                          showInvalidKeyAlert();
                          console.error("getsurvey - error", e);
                          setIsLoading(false);
                        });
                    } else {
                      setIsValidKey(false);
                      setIsLoading(false);
                      showInvalidKeyAlert();
                    }
                  })
                  .catch((e) => {
                    console.error("VALIDATE KEY-ERROR", e);
                    setIsLoading(false);
                  });
              }
            })
            .catch((e) => {
              console.error("getsurvey - error", e);
              setIsLoading(false);
            });
        })
        .catch((e) => {
          setSurveyIsComplete(true);

          showInvalidKeyAlert();
          console.error("getsurvey - error", e);
          setIsLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.search]);

  React.useEffect(() => {
    const handleBeforeUnload = () => {
      resetPreviewSurveyAnswers(); // Limpia el LocalStorage del preview
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [resetPreviewSurveyAnswers]);

  return (
    <div className="App">
      {surveyIsComplete || !isValidKey ? null : (
        <React.Fragment>
          <ThemeProvider theme={encuestaTheme}>
            <CssBaseline />

            {isLoading ? (
              <Paper
                elevation={0}
                sx={{
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  mb: { xs: 3, md: 6 },
                  p: { xs: 2, md: 3 },
                }}
              >
                <Box
                  sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    display: "flex",
                    justifyContent: "center",
                    mb: { xs: 3, md: 6 },
                    p: { xs: 2, md: 32 },
                  }}
                >
                  <CircularProgress />
                </Box>
              </Paper>
            ) : isUpdateData ? (
              <UpdateDataForm />
            ) : (
              <Survey
                survey={survey}
                handleClickSettingsButton={() => handleClickSettingsButton()}
                isPreview={isPreview}
                isSendAnswers={isSendAnswers}
                entidadId={entidadId}
              />
            )}

            {isPreview && (
              <FloatButton
                handleOnClick={() => handleClickSettingsButton()}
                verticalPosition={64}
              >
                <Fab
                  size="small"
                  aria-label="open configuration drawer button"
                  color="primary"
                >
                  <MaterialIcons.SettingsIcon />
                </Fab>
              </FloatButton>
            )}

            <ScrollToTop>
              <Fab
                size="small"
                aria-label="scroll to top button"
                color="primary"
              >
                <MaterialIcons.KeyboardArroyUpIcon />
              </Fab>
            </ScrollToTop>
          </ThemeProvider>

          {isPreview && (
            <ConfigurationDrawer
              open={openSettingsDrawer}
              onClose={() => handleCloseSettingsDrawer()}
              surveyId={survey?.Id}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
}

export default App;
