import * as React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import Box from "@mui/material/Box";
import Zoom from "@mui/material/Zoom";

import { styled } from "@mui/material";

type FloatButtonType = {
  children: React.ReactElement;
  verticalPosition?: number;
  horizontalPositon?: number;

  handleOnClick: () => void;
};

const FloatButton: React.FC<FloatButtonType> = ({
  children,
  handleOnClick,
  horizontalPositon,
  verticalPosition,
}) => {
  const FloatButtonStyled = styled(Box)(() => ({
    zIndex: 11,
    position: "fixed",
    right: horizontalPositon || 16,
    bottom: verticalPosition || 64,
  }));

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Zoom in={trigger}>
      <FloatButtonStyled onClick={() => handleOnClick()} role="presentation">
        {children}
      </FloatButtonStyled>
    </Zoom>
  );
};

export default FloatButton;
