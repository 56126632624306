import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArroyUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PaletteIcon from "@mui/icons-material/Palette";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import SettingsIcon from "@mui/icons-material/Settings";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import TextFormatIcon from "@mui/icons-material/TextFormat";

//MDI
import Numeric1Circle from "mdi-material-ui/Numeric1Circle";
import Numeric2Circle from "mdi-material-ui/Numeric2Circle";
import Numeric3Circle from "mdi-material-ui/Numeric3Circle";
import Numeric4Circle from "mdi-material-ui/Numeric4Circle";
import Numeric5Circle from "mdi-material-ui/Numeric5Circle";
import Numeric6Circle from "mdi-material-ui/Numeric6Circle";
import Numeric7Circle from "mdi-material-ui/Numeric7Circle";
import Numeric8Circle from "mdi-material-ui/Numeric8Circle";
import Numeric9Circle from "mdi-material-ui/Numeric9Circle";
import Numeric10Circle from "mdi-material-ui/Numeric10Circle";

export const MaterialIcons = {
  CheckCircleIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  FontDownloadIcon,
  FormatColorTextIcon,
  KeyboardArrowLeftIcon,
  KeyboardArrowRightIcon,
  KeyboardArroyUpIcon,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  PaletteIcon,
  RadioButtonCheckedIcon,
  RadioButtonUncheckedIcon,
  SettingsIcon,
  TextFieldsIcon,
  TextFormatIcon,
};

export const MaterialIconsArray = [
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
  Numeric1Circle,
  Numeric2Circle,
  Numeric3Circle,
  Numeric4Circle,
  Numeric5Circle,
  Numeric6Circle,
  Numeric7Circle,
  Numeric8Circle,
  Numeric9Circle,
  Numeric10Circle,
];
