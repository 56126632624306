import * as React from "react";
import axios from "axios";

import { styled } from "@mui/material/styles";

import Box from "@mui/material/Box";

const StyledImg = styled("img")({
  maxWidth: "35%",
  height: 233,
});

type BoxImageType = {
  text: string;
  imageId: string;
};

const BoxImage: React.FC<BoxImageType> = ({ imageId, text }) => {
  const [imagen, setImagen] = React.useState<any>(null);

  React.useEffect(() => {
    const getImagen = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_DO_URL}/getimage/`,
          {
            imageId,
          }
        );
        setImagen(response.data);
      } catch (error) {
        console.log("BoxImage - error ", error);
      }
    };

    getImagen();
  }, [imageId]);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {imagen && (
        <StyledImg
          src={`data:image/png;base64,${imagen}`}
          alt={`${text}-imagen-referencial`}
          sx={{
            maxHeight: { xs: 233, md: 104 },
            maxWidth: { md: 327 },
          }}
        />
      )}
    </Box>
  );
};

export default BoxImage;
