import React, { useState, useEffect, useCallback } from "react";
import { shallow } from "zustand/shallow";

import TextField from "@mui/material/TextField";

import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useErrorsStore } from "../../store/errorStore";
import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";

import { validateEmail } from "../../utils";

type EmailInputType = {
  isRequired?: boolean;
  preguntaId: string;
};

const EmailInput: React.FC<EmailInputType> = ({ isRequired, preguntaId }) => {
  const [emailValue, setEmailValue] = useState<string>("");
  const [isEmptyError, setIsEmptyError] = useState<boolean>(false);
  const [isFormatError, setIsFormatError] = useState<boolean>(false);

  const addEntityAnswer = useNewAnswersStore(
    useCallback((state) => state.addEntityAnswer, [])
  );

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    (state) => state.addPreviewAnswer
  );

  const isGlobalNextButtonClicked = useErrorsStore(
    (state) => state.isGlobalNextButtonClicked,
    shallow
  );

  const globalNextButtonClicked = useErrorsStore(
    useCallback((state) => state.globalNextButtonClicked, [])
  );

  const addEntityError = useErrorsStore(
    useCallback((state) => state.addEntityError, [])
  );

  const email = useSurveyAnswer(preguntaId) || "";

  const handleOnChange = useCallback(
    (text: string) => {
      setEmailValue(text);
      if (isPreviewSurvey) {
        addPreviewAnswer(preguntaId, text);
      } else {
        addEntityAnswer(preguntaId, text);
        const trimmedEmail = text.trim();
        const isEmailValid = validateEmail(trimmedEmail);

        if (isRequired) {
          addEntityError(preguntaId, trimmedEmail === "" || !isEmailValid);
          setIsEmptyError(trimmedEmail === "");
          setIsFormatError(!isEmailValid);
        }
      }
    },
    [
      addEntityAnswer,
      addEntityError,
      addPreviewAnswer,
      isPreviewSurvey,
      isRequired,
      preguntaId,
    ]
  );

  useEffect(() => {
    setEmailValue(email || "");
    if (isRequired) {
      if (email) {
        addEntityError(preguntaId, email?.trim() === "");
        addEntityError(preguntaId, !validateEmail(email ?? ""));
      } else {
        addEntityError(preguntaId, true);
      }
    }
  }, [addEntityError, email, isRequired, preguntaId]);

  useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      addEntityError(preguntaId, emailValue.trim() === "");
      addEntityError(preguntaId, !validateEmail(emailValue));
      setIsEmptyError(emailValue === "");
      setIsFormatError(!validateEmail(emailValue));

      globalNextButtonClicked(false);
    }
  }, [
    addEntityError,
    emailValue,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
  ]);

  return (
    <React.Fragment>
      <TextField
        type="email"
        variant="outlined"
        margin="normal"
        value={emailValue}
        onChange={(e) => handleOnChange(e.target.value)}
        required={isRequired}
        error={isEmptyError || isFormatError}
        fullWidth
      />

      {(isEmptyError || isFormatError) && (
        <ErrorMessage
          messageType={
            isEmptyError ? "required" : isFormatError ? "invalidEmail" : ""
          }
        />
      )}
    </React.Fragment>
  );
};

export default EmailInput;
