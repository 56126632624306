import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

type PreguntaColorState = {
  preguntaFontFamily: string;
  preguntaFontColor: string;
};

type PreguntaColorActions = {
  setPreguntaFontFamily: (fontFamily: string) => void;
  setPreguntaFontColor: (fontColor: string) => void;

  setAllPreguntaStyles: (preguntaStyle: PreguntaColorState) => void;
};

const initialPreguntaColorState: PreguntaColorState = {
  preguntaFontFamily: "Hanken Grotesk",
  preguntaFontColor: "#000000",
};

export const useColorPreguntasStore = create<
  PreguntaColorState & PreguntaColorActions
>()(
  persist(
    (set, get) => ({
      ...initialPreguntaColorState,
      setPreguntaFontFamily: (preguntaFontFamily) =>
        set((state) => ({ ...state, preguntaFontFamily })),
      setPreguntaFontColor: (preguntaFontColor) =>
        set((state) => ({ ...state, preguntaFontColor })),
      setAllPreguntaStyles: ({ preguntaFontColor, preguntaFontFamily }) => {
        set({
          preguntaFontColor,
          preguntaFontFamily,
        });
      },
    }),
    {
      name: "creatio-survey-color-preguntas-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<PreguntaColorState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
