import * as React from "react";
import { shallow } from "zustand/shallow";

import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useErrorsStore } from "../../store/errorStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";

type MultipleInputTextType = {
  answerText: string;
  isRequired?: boolean;
  options: any[];
  preguntaId: string;
};

type TextInputType = {
  answerId: string;
  answerText: string;
  isGlobalNextButtonClicked: boolean;
  globalNextButtonClicked: (isClicked: boolean) => void;
  isRequired: boolean;
  order: number;
  preguntaId: string;
  respuesta: string;
  handleError: (preguntaId: string, hasError: boolean) => void;
  onChangeText: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    order: number
  ) => void;
};

const TextInput: React.FC<TextInputType> = ({
  answerId,
  answerText,
  globalNextButtonClicked,
  isGlobalNextButtonClicked,
  isRequired,
  order,
  preguntaId,
  respuesta,

  handleError,
  onChangeText,
}) => {
  const [resp, setResp] = React.useState<string>("");
  const [isEmptyError, setIsEmptyError] = React.useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChangeText(e, order);
    setResp(value);

    if (isRequired) {
      handleError(preguntaId, value.trim() === "");
      setIsEmptyError(value.trim() === "");
    }
  };

  React.useEffect(() => {
    setResp(respuesta || "");
  }, [respuesta]);

  React.useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      handleError(preguntaId, resp.trim() === "");
      setIsEmptyError(resp.trim() === "");
      globalNextButtonClicked(false);
    }
  }, [
    globalNextButtonClicked,
    handleError,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
    resp,
  ]);

  return (
    <React.Fragment>
      <FormControl sx={{ py: 2 }} fullWidth>
        <Typography
          id={`${answerId}-label-id`}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          {answerText}
        </Typography>
        <TextField
          id={`${answerId}-id`}
          name={answerId}
          onChange={handleChange}
          variant="filled"
          value={resp}
          required={isRequired}
          focused={isEmptyError}
          error={isEmptyError}
          fullWidth
        />
      </FormControl>

      {isEmptyError && <ErrorMessage messageType="required" />}
    </React.Fragment>
  );
};

const MultipleInputText: React.FC<MultipleInputTextType> = ({
  answerText,
  isRequired,
  options,
  preguntaId,
}) => {
  const respuestas = useSurveyAnswer(preguntaId);

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const addEntityAnswer = useNewAnswersStore(
    React.useCallback((state) => state.addEntityAnswer, [])
  );

  const addEntityError = useErrorsStore(
    React.useCallback((state) => state.addEntityError, [])
  );

  const globalNextButtonClicked = useErrorsStore(
    React.useCallback((state) => state.globalNextButtonClicked, [])
  );

  const isGlobalNextButtonClicked = useErrorsStore(
    (state) => state.isGlobalNextButtonClicked,
    shallow
  );

  const handleChangeText = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    order: number
  ) => {
    if (!isPreviewSurvey) {
      addEntityAnswer(preguntaId, {
        ...respuestas,
        [e.target.name]: { resp: e.target.value, order },
      });
    } else {
      addPreviewAnswer(preguntaId, {
        ...respuestas,
        [e.target.name]: { resp: e.target.value, order },
      });
    }
  };

  return (
    <React.Fragment>
      {options && options.length > 0 ? (
        options.map((answer, index) => {
          const respuesta = respuestas?.[answer.Id]?.resp || "";
          return (
            <TextInput
              key={index}
              answerId={answer.Id}
              order={answer.BGlobalOrderAnswer}
              answerText={answer.BGlobalValueText}
              onChangeText={handleChangeText}
              respuesta={respuesta}
              isRequired={isRequired || false}
              handleError={addEntityError}
              preguntaId={preguntaId}
              isGlobalNextButtonClicked={isGlobalNextButtonClicked}
              globalNextButtonClicked={globalNextButtonClicked}
            />
          );
        })
      ) : (
        <FormControl sx={{ py: 2 }} fullWidth>
          <Typography
            id={`${answerText}-label-id`}
            sx={{ display: "flex", justifyContent: "flex-start" }}
          >
            {answerText}
          </Typography>
          <TextField id={`${answerText}-id`} variant="filled" fullWidth />
        </FormControl>
      )}
    </React.Fragment>
  );
};

export default MultipleInputText;
