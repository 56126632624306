import * as React from "react";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";
import { useErrorsStore } from "../../store/errorStore";

type NewCheckboxOptionsType = {
  isRequired?: boolean;
  options: any[];
  preguntaId: string;
};

const NewCheckboxOptions: React.FC<NewCheckboxOptionsType> = ({
  options,
  preguntaId,
  isRequired,
}) => {
  const [isOneClicked, setIsOneClicked] = React.useState<boolean>(false);
  const [isRequiredError, setIsRequiredError] = React.useState<boolean>(false);
  const allStoreOptions: any[] = useSurveyAnswer(preguntaId);

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const addEntityAnswer = useNewAnswersStore(
    React.useCallback((state) => state.addEntityAnswer, [])
  );

  const addEntityError = useErrorsStore(
    React.useCallback((state) => state.addEntityError, [])
  );

  const globalNextButtonClicked = useErrorsStore(
    React.useCallback((state) => state.globalNextButtonClicked, [])
  );

  const isGlobalNextButtonClicked = useErrorsStore(
    (state) => state.isGlobalNextButtonClicked,
    shallow
  );

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    optionId: string
  ) => {
    setIsOneClicked(event.target.checked);
    if (allStoreOptions && allStoreOptions.length > 0) {
      if (!isPreviewSurvey) {
        addEntityAnswer(
          preguntaId,
          allStoreOptions.map((option) => {
            if (option.Id === optionId) {
              return { ...option, selected: event.target.checked };
            } else {
              return { ...option };
            }
          })
        );

        let isOneSelected: boolean = false;
        for (let index = 0; index < allStoreOptions.length; index++) {
          const element = allStoreOptions[index];
          if (element.selected) {
            isOneSelected = true;
            break;
          }
        }

        if (isRequired) {
          addEntityError(preguntaId, !isOneSelected);
        }
      } else {
        addPreviewAnswer(
          preguntaId,
          allStoreOptions.map((option) => {
            if (option.Id === optionId) {
              return { ...option, selected: event.target.checked };
            } else {
              return { ...option };
            }
          })
        );
      }
    } else {
      if (!isPreviewSurvey) {
        addEntityAnswer(
          preguntaId,
          options.map((option) => {
            if (option.Id === optionId) {
              return { ...option, selected: event.target.checked };
            } else {
              return { ...option };
            }
          })
        );

        let isOneSelected: boolean = false;
        for (let index = 0; index < options.length; index++) {
          const element = options[index];
          if (element.selected) {
            isOneSelected = true;
            break;
          }
        }

        if (isRequired) {
          addEntityError(preguntaId, !isOneSelected);
        }
      } else {
        addPreviewAnswer(
          preguntaId,
          allStoreOptions.map((option) => {
            if (option.Id === optionId) {
              return { ...option, selected: event.target.checked };
            } else {
              return { ...option };
            }
          })
        );
      }
    }
  };

  React.useEffect(() => {
    if (!allStoreOptions) {
      if (!isPreviewSurvey) {
        addEntityAnswer(
          preguntaId,
          options.map((option) => {
            return { ...option, selected: false };
          })
        );
      } else {
        addPreviewAnswer(
          preguntaId,
          options.map((option) => {
            return { ...option, selected: false };
          })
        );
      }
    }
  }, [
    addEntityAnswer,
    addPreviewAnswer,
    allStoreOptions,
    isPreviewSurvey,
    options,
    preguntaId,
  ]);

  React.useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      if (allStoreOptions) {
        let isOneSelected: boolean = false;
        for (let index = 0; index < allStoreOptions.length; index++) {
          const element = allStoreOptions[index];
          if (element.selected) {
            isOneSelected = true;
            break;
          }
        }
        addEntityError(preguntaId, !isOneSelected);
        setIsRequiredError(!isOneSelected);
      }
      globalNextButtonClicked(false);
    }
  }, [
    addEntityError,
    allStoreOptions,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
  ]);

  React.useEffect(() => {
    if (isRequired) {
      if (allStoreOptions) {
        let isOneSelected: boolean = false;
        for (let index = 0; index < allStoreOptions.length; index++) {
          const element = allStoreOptions[index];
          if (element.selected) {
            isOneSelected = true;
            break;
          }
        }
        addEntityError(preguntaId, !isOneSelected);
      }
    }
  }, [
    addEntityError,
    allStoreOptions,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
  ]);

  React.useEffect(() => {
    if (isRequired) {
      addEntityError(preguntaId, !isOneClicked);
      if (isOneClicked) {
        setIsRequiredError(!isOneClicked);
      }
    }
  }, [isRequired, isOneClicked, addEntityError, preguntaId]);

  const renderOptions = (optionsArray: any[]) => (
    <Grid container>
      <Grid item xs={12}>
        <Stack spacing={2}>
          {optionsArray.map((opt) => (
            <Paper
              key={opt.Id}
              sx={{
                backgroundColor: "inherit",
                display: "flex",
                borderRadius: "8px",
              }}
              elevation={4}
            >
              <FormControlLabel
                key={opt.Id}
                control={
                  <Checkbox
                    value={opt.Id}
                    checked={opt.selected || false}
                    onChange={(e) => handleOnChange(e, opt.Id)}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 48 } }}
                  />
                }
                label={opt.BGlobalValueText}
                sx={{ paddingX: 2, paddingY: 1, flexGrow: 1 }}
              />
            </Paper>
          ))}
        </Stack>
        {isRequiredError && (
          <Box marginTop={2}>
            <ErrorMessage messageType="required" />
          </Box>
        )}
      </Grid>
    </Grid>
  );

  return (
    <>
      {allStoreOptions && allStoreOptions.length > 0
        ? renderOptions(allStoreOptions)
        : renderOptions(options)}
    </>
  );
};

export default NewCheckboxOptions;
