import axios from "axios";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { BGlobalQuestionType } from "../types";

type PreviewAnswersState = {
  allQuestions: BGlobalQuestionType[];
  answers: Record<string, any>;
  currentPage: number;
  currentPageId: string;
  entityId: string;
  finishSurvey: boolean;
  isPreviewSurvey: boolean;
  previewCurrentPage: number;
  previewCurrentPageId: string;
  previewSurveyAnswers: Record<string, any>;
  surveyCurrentPage: number;
  previewSurveyId: string;
  surveys: string[];
  surveysCurrentPage: Record<string, any>;
  surveysPreviewCurrentPage: Record<string, any>;
};

type PreviewAnswersAction = {
  addPreviewAnswer: (questionId: string, answer: any) => void;
  addQuestion: (questions: BGlobalQuestionType[]) => void;
  addPreviewSurvey: (previewSurveyId: string) => void;

  goToHomePage: () => void;
  goToLastPage: (lastPage: number) => void;
  goToNextPage: () => void;
  goToPrevPage: () => void;

  sendAllAnswers: () => void;

  setCurrentPage: (currentPage: number) => void;
  setCurrentPageId: (currentPageId: string) => void;
  setEntityId: (entityId: string) => void;
  setFinishSurvey: (finishSurvey: boolean) => void;
  setIsPreviewSurvey: (isPreviewSurvey: boolean) => void;
  setPreviewCurrentPage: (previewCurrentPage: number) => void;
  setPreviewCurrentPageId: (previewCurrentPageId: string) => void;
  setSurveyCurrentPage: (previewSurveyId: string, currentPage: number) => void;
  setSurveyPreviewCurrentPage: (
    previewSurveyId: string,
    currentPage: number
  ) => void;
  setPreviewSurveyId: (previewSurveyId: string) => void;

  returnPreviewSurveyCurrentPage: () => number;
  returnSurveyCurrentPage: () => number;

  resetPreviewSurveyAnswers: () => void;
};

// define the initial state
const initialPreviewAnswersState: PreviewAnswersState = {
  allQuestions: [],
  answers: {},
  currentPage: 0,
  currentPageId: "",
  entityId: "",
  finishSurvey: false,
  isPreviewSurvey: true,
  previewCurrentPage: 0,
  previewCurrentPageId: "",
  previewSurveyAnswers: {},
  surveyCurrentPage: 0,
  previewSurveyId: "",
  surveys: [],
  surveysCurrentPage: {},
  surveysPreviewCurrentPage: {},
};

export const useNewPreviewAnswersStore = create<
  PreviewAnswersState & PreviewAnswersAction
>()(
  persist(
    (set, get) => ({
      ...initialPreviewAnswersState,
      addPreviewAnswer: (questionId: string, answer: any) => {
        console.log("addPreviewAnswer", answer);
        set((state) => ({
          ...state,
          answers: {
            ...state.answers,
            [get().previewSurveyId]: {
              ...state.answers[get().previewSurveyId],
              [questionId]: answer,
            },
          },
          previewSurveyAnswers: {
            ...state.previewSurveyAnswers,
            [get().previewSurveyId]: {
              ...state.previewSurveyAnswers[get().previewSurveyId],
              [get().previewCurrentPageId]: {
                ...(state.previewSurveyAnswers[get().previewSurveyId] && {
                  ...state.previewSurveyAnswers[get().previewSurveyId][
                    get().previewCurrentPageId
                  ],
                }),
                [questionId]: answer,
              },
            },
          },
        }));
      },
      addQuestion: (questions: BGlobalQuestionType[]) => {
        console.log("addQuestion");
        set((state) => {
          const combinedArray = questions
            .concat(state.allQuestions)
            .filter((obj, index, self) => {
              return index === self.findIndex((o) => o.Id === obj.Id);
            });

          return {
            ...state,
            allQuestions: combinedArray,
          };
        });
      },
      addPreviewSurvey: (previewSurveyId: string) => {
        console.log("addPreviewSurvey");
        set((state) => ({
          ...state,
          surveys: [...state.surveys, previewSurveyId],
        }));
      },
      goToHomePage: () => {
        console.log("goToHomePage");
        set((state) => ({ ...state, currentPage: 0 }));
      },
      goToLastPage: (lastPage: number) => {
        console.log("goToLastPage");
        set((state) => ({ ...state, currentPage: lastPage }));
      },
      goToNextPage: () => {
        console.log("goToNextPage");
        set((state) => ({ ...state, currentPage: get().currentPage + 1 }));
      },
      goToPrevPage: () => {
        console.log("goToPrevPage");
        set((state) => ({ ...state, currentPage: get().currentPage - 1 }));
      },
      sendAllAnswers: async () => {
        console.log("sendAllAnswers");
        const todasRespuestas =
          get().previewSurveyAnswers[get().previewSurveyId];
        const todasPaginas = Object.keys(todasRespuestas);

        const todasPreguntas = get().allQuestions;

        let preguntasUpload: BGlobalQuestionType[] = [];

        let uploadFiles: any[] = [];

        for (let index = 0; index < todasPreguntas.length; index++) {
          const pregunta = todasPreguntas[index];
          if (
            pregunta.BGlobalType.Description === "mupload" ||
            pregunta.BGlobalType.Description === "upload"
          ) {
            preguntasUpload.push(pregunta);
          }
        }

        const setUploadQuestions = () => {
          for (const currentPageId in todasRespuestas) {
            const questions: string[] = Object.keys(
              todasRespuestas[currentPageId]
            );

            const findedQuestion = preguntasUpload.find((preguntaUpload) => {
              return questions.includes(preguntaUpload.Id);
            });

            if (findedQuestion) {
              for (let index = 0; index < preguntasUpload.length; index++) {
                const preguntaUpload = preguntasUpload[index];
                if (questions.includes(preguntaUpload.Id)) {
                  uploadFiles.push({
                    type: preguntaUpload.BGlobalType.Description,
                    files: todasRespuestas[currentPageId][preguntaUpload.Id],
                  });
                }
              }
            }
          }
        };

        if (preguntasUpload.length > 0) {
          setUploadQuestions();
        }

        const formData = new FormData();

        if (uploadFiles.length > 0) {
          for (let index = 0; index < uploadFiles.length; index++) {
            const uploadFilesArray = uploadFiles[index];
            if (uploadFilesArray.type === "mupload") {
              for (
                let index = 0;
                index < uploadFilesArray.files.length;
                index++
              ) {
                const uploadArray = uploadFilesArray.files[index];
                formData.append("files", uploadArray);
              }
            } else {
              formData.append("file", uploadFilesArray.files);
            }
          }
        }

        formData.append("pagesList", JSON.stringify(todasPaginas));

        formData.append("entityId", get().entityId);

        formData.append("questions", JSON.stringify(get().allQuestions));

        formData.append(
          "previewSurveyAnswers",
          JSON.stringify(get().previewSurveyAnswers)
        );

        formData.append("previewSurveyId", get().previewSurveyId);

        axios
          .post(`${process.env.REACT_APP_DO_URL}/enviarRespuestas`, formData)
          .then((res) => console.log("RESPUESTAS ENVIADAS", res.data))
          .catch((err) => console.log("ERROR ENVIAR RESPUESTAS", err));
      },
      setCurrentPage: (currentPage: number) => {
        console.log("setCurrentPage");
        set((state) => ({ ...state, currentPage }));
      },
      setCurrentPageId: (currentPageId) => {
        console.log("setCurrentPageId");
        set((state) => ({
          ...state,
          currentPageId,
        }));
      },
      setFinishSurvey: (finishSurvey: boolean) => {
        console.log("setFinishSurvey");
        set((state) => ({
          ...state,
          finishSurvey,
        }));
      },
      setIsPreviewSurvey: (isPreviewSurvey: boolean) => {
        console.log("setIsPreviewSurvey");
        set((state) => ({
          ...state,
          isPreviewSurvey,
        }));
      },
      setSurveyCurrentPage: (previewSurveyId, currentPage) => {
        console.log("setSurveyCurrentPage");
        set((state) => ({
          surveysCurrentPage: {
            ...state.surveysCurrentPage,
            [previewSurveyId]: { currentPage },
          },
        }));
      },
      setSurveyPreviewCurrentPage: (previewSurveyId, previewCurrentPage) => {
        console.log("setSurveyPreviewCurrentPage", previewCurrentPage);
        set((state) => ({
          surveysPreviewCurrentPage: {
            ...state.surveysPreviewCurrentPage,
            [previewSurveyId]: { previewCurrentPage },
          },
        }));
      },
      setEntityId: (entityId: string) => {
        console.log("setEntityId");
        set((state) => ({ ...state, entityId }));
      },
      setPreviewCurrentPage: (previewCurrentPage: number) => {
        console.log("setPreviewCurrentPage");
        set((state) => ({ ...state, previewCurrentPage }));
      },
      setPreviewCurrentPageId: (previewCurrentPageId: string) => {
        console.log("setPreviewCurrentPageId");
        set((state) => ({
          ...state,
          previewCurrentPageId,
        }));
      },
      setPreviewSurveyId: (previewSurveyId: string) => {
        console.log("setPreviewSurveyId");
        set((state) => ({ ...state, previewSurveyId }));
      },
      returnSurveyCurrentPage: () => {
        return (
          get().surveysCurrentPage[get().previewSurveyId]?.currentPage ||
          get().currentPage
        );
      },
      returnPreviewSurveyCurrentPage: () => {
        return (
          get().surveysPreviewCurrentPage[get().previewSurveyId]
            ?.previewCurrentPage || get().previewCurrentPage
        );
      },
      resetPreviewSurveyAnswers: () => {
        console.log("resetPreviewSurveyAnswers");
        set(initialPreviewAnswersState);
      },
    }),
    {
      name: "preview-creatio-answers-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<PreviewAnswersState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
