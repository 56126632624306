import * as React from "react";
import { useTranslation } from "react-i18next";
import { shallow } from "zustand/shallow";

import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import CustomTooltip from "../CustomTooltip/CustomTooltip";

import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";
import { useErrorsStore } from "../../store/errorStore";

type TableMatrizType = {
  isRequired?: boolean;
  matrixOptions: any[];
  preguntaId: string;
};

const TableMatriz: React.FC<TableMatrizType> = ({
  isRequired,
  matrixOptions,
  preguntaId,
}) => {
  const [board, setBoard] = React.useState<any[]>([]);
  const [agrupado, setAgrupado] = React.useState<any>([]);
  const [isEmptyError, setIsEmptyError] = React.useState<boolean>(false);

  const { t } = useTranslation();

  const seleccionado = useSurveyAnswer(preguntaId);

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const addEntityAnswer = useNewAnswersStore(
    React.useCallback((state) => state.addEntityAnswer, [])
  );

  const addEntityError = useErrorsStore(
    React.useCallback((state) => state.addEntityError, [])
  );

  const globalNextButtonClicked = useErrorsStore(
    (state) => state.globalNextButtonClicked
  );

  const isGlobalNextButtonClicked = useErrorsStore(
    (state) => state.isGlobalNextButtonClicked,
    shallow
  );

  const setFinishSurvey = useNewAnswersStore((state) => state.setFinishSurvey);

  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    filaId: string,
    columnId: string
  ) => {
    if (!isPreviewSurvey) {
      addEntityAnswer(preguntaId, {
        ...seleccionado,
        [filaId]: event.target.value,
      });
    } else {
      addPreviewAnswer(preguntaId, {
        ...seleccionado,
        [filaId]: event.target.value,
      });
    }

    const encontradoColumna = matrixOptions.find(
      (matrixOption) => matrixOption.Id === columnId
    );

    setFinishSurvey(encontradoColumna && encontradoColumna.BGlobalEndSurvey);
  };

  const renderRadioIcon = (isSelected: boolean) => {
    return isSelected ? (
      <MaterialIcons.CheckCircleIcon />
    ) : (
      <MaterialIcons.RadioButtonUncheckedIcon color="primary" />
    );
  };

  const reorderBoard = (arreglo: any[]) => {
    // Agrupamos los objetos por BGlobalType.Description
    const agrupado = arreglo.reduce((resultado, objeto) => {
      const descripcion = objeto.BGlobalType.Description;
      if (!resultado[descripcion]) {
        resultado[descripcion] = [];
      }
      resultado[descripcion].push(objeto);
      return resultado;
    }, {});

    setAgrupado(agrupado);
  };

  React.useEffect(() => {
    if (matrixOptions.length > 0 && (!board || board.length <= 0)) {
      const filas: any[] = [];
      const cols: any[] = [];

      for (let index = 0; index < matrixOptions.length; index++) {
        const element = matrixOptions[index];
        if (element.BGlobalType.Description === "fil") {
          filas.push({ ...element });
        } else {
          cols.push({ ...element });
        }
      }

      reorderBoard(matrixOptions);
      const tabla: any[] = [];
      tabla.push(filas);
      tabla.push(cols);
      setBoard(tabla);
    }
  }, [matrixOptions, board]);

  React.useEffect(() => {
    if (agrupado["col"] && agrupado["col"].length > 0) {
      console.log("React.useEffect - agrupado", agrupado);
      let selected: boolean = false;
      for (let indexFila = 0; indexFila < agrupado["fil"].length; indexFila++) {
        if (selected) {
          break;
        } else {
          const fila = agrupado["fil"][indexFila];
          for (
            let indexColumna = 0;
            indexColumna < agrupado["col"].length;
            indexColumna++
          ) {
            const columna = agrupado["col"][indexColumna];
            if (columna.BGlobalEndSurvey) {
              console.log("endsurvey", true);
            }
            if (columna.BGlobalIsDefault) {
              addEntityAnswer(preguntaId, {
                ...seleccionado,
                [fila.Id]: `${fila.Id}-${columna.Id}`,
              });
              selected = true;
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agrupado]);

  React.useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      if (seleccionado) {
        addEntityError(
          preguntaId,
          !(Object.keys(seleccionado).length === agrupado["fil"].length)
        );
        setIsEmptyError(
          !(Object.keys(seleccionado).length === agrupado["fil"].length)
        );
      } else {
        addEntityError(preguntaId, true);
        setIsEmptyError(true);
      }

      globalNextButtonClicked(false);
    }
  }, [
    addEntityError,
    agrupado,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
    seleccionado,
  ]);

  React.useEffect(() => {
    if (!isPreviewSurvey) {
      addEntityAnswer(preguntaId, seleccionado ?? "");
    } else {
      addPreviewAnswer(preguntaId, seleccionado ?? "");
    }
  }, [
    addEntityAnswer,
    addPreviewAnswer,
    isPreviewSurvey,
    preguntaId,
    seleccionado,
  ]);

  React.useEffect(() => {
    if (isRequired) {
      addEntityError(preguntaId, Boolean(!seleccionado));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEntityError, isRequired, preguntaId]);

  React.useEffect(() => {
    if (isRequired) {
      if (seleccionado && agrupado["fil"]) {
        addEntityError(
          preguntaId,
          !(Object.keys(seleccionado).length === agrupado["fil"].length)
        );
      } else {
        addEntityError(preguntaId, true);
      }
    }
  }, [addEntityError, agrupado, isRequired, preguntaId, seleccionado]);

  return board.length > 0 ? (
    <React.Fragment>
      <TableContainer
        component={Paper}
        sx={{ backgroundColor: "transparent" }}
        elevation={0}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ borderBottom: "1px solid #dddddd" }} />
              {agrupado["col"].map((col: any, index: number) => {
                return (
                  <TableCell
                    key={index}
                    align="center"
                    sx={{ borderBottom: "1px solid #dddddd" }}
                  >
                    <Typography>{col.BGlobalValueText}</Typography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {agrupado["fil"].map((fila: any, indexFila: number) => (
              <TableRow key={indexFila}>
                <TableCell
                  component="th"
                  scope="row"
                  align="center"
                  sx={{ border: "1px solid #dddddd" }}
                >
                  <Typography>{fila.BGlobalValueText}</Typography>
                </TableCell>
                {agrupado["col"].map((columna: any, indexColumna: number) => (
                  <TableCell
                    key={indexColumna}
                    align="center"
                    sx={{ border: "1px solid #dddddd", padding: 0 }}
                  >
                    {/* <IconButton
                    onClick={() =>
                      handleRadioChange(`${fila.Id}-${columna.Id}`)
                    }
                  >
                    {renderRadioIcon(
                      seleccionado === `${fila.Id}-${columna.Id}`
                    )}
                  </IconButton> */}
                    <CustomTooltip
                      title={t("errorMessage.required")}
                      open={
                        indexColumna === 0 &&
                        isEmptyError &&
                        !Boolean(seleccionado && seleccionado[fila.Id])
                      }
                      placement="top-start"
                      arrow
                      sx={{
                        "& .MuiTooltip-arrow": {
                          color: "#d32f2f", // Color de la flecha
                        },
                      }}
                    >
                      <Radio
                        checked={
                          seleccionado
                            ? seleccionado[fila.Id] ===
                              `${fila.Id}-${columna.Id}`
                            : false
                        }
                        onChange={(e) =>
                          handleRadioChange(e, fila.Id, columna.Id)
                        }
                        value={`${fila.Id}-${columna.Id}`}
                        icon={renderRadioIcon(false)}
                        checkedIcon={renderRadioIcon(true)}
                        name={`radio-${indexFila}-${indexColumna}-${fila.Id}-${columna.Id}`}
                        id={`radio-${indexFila}-${indexColumna}-${fila.Id}-${columna.Id}`}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 60,
                          },
                          padding: 0,
                        }}
                      />
                    </CustomTooltip>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  ) : (
    <CircularProgress />
  );
};

export default TableMatriz;
