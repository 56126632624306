import * as React from "react";
import { shallow } from "zustand/shallow";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { InputBaseComponentProps } from "@mui/material";

import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";
import { useErrorsStore } from "../../store/errorStore";

// import { isInRange } from "../../utils";

import { errorMessageType } from "../../types";

type NumberInputType = {
  decimal?: boolean;
  isRequired?: boolean;
  preguntaId: string;
};

const NumberInput: React.FC<NumberInputType> = ({
  decimal = false,
  isRequired,
  preguntaId,
}) => {
  const [isEmptyError, setIsEmptyError] = React.useState<boolean>(false);
  const [invalidNumberError, setInvalidNumberError] =
    React.useState<boolean>(false);
  // const [isOutOfRangeError, setIsOutOfRangeError] =
  //   React.useState<boolean>(false);
  const [errorType, setErrorType] = React.useState<errorMessageType>("");

  const value = useSurveyAnswer(preguntaId);

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const addEntityAnswer = useNewAnswersStore(
    React.useCallback((state) => state.addEntityAnswer, [])
  );

  const addEntityError = useErrorsStore(
    React.useCallback((state) => state.addEntityError, [])
  );

  const globalNextButtonClicked = useErrorsStore(
    React.useCallback((state) => state.globalNextButtonClicked, [])
  );

  const isGlobalNextButtonClicked = useErrorsStore(
    (state) => state.isGlobalNextButtonClicked,
    shallow
  );

  const handleChange = (numericValue: string) => {
    if (!isPreviewSurvey) {
      if (decimal) {
        if (/^[0-9]*\.?[0-9]*$/.test(numericValue)) {
          setInvalidNumberError(false);
          if (isRequired) {
            /*  if (!isInRange(100, 10, Number(numericValue))) {
              addEntityError(preguntaId, true);
            } else {
              addEntityError(preguntaId, false);
            } */
            addEntityError(preguntaId, false);
          }
        } else {
          setInvalidNumberError(true);
          setErrorType("invalidNumber");
          if (isRequired) {
            addEntityError(preguntaId, true);
          }
        }
        setIsEmptyError(false);
        addEntityAnswer(preguntaId, numericValue);
      } else {
        if (/^-?\d+$/.test(numericValue)) {
          setInvalidNumberError(false);
          if (isRequired) {
            /* if (!isInRange(100, 10, Number(numericValue))) {
              addEntityError(preguntaId, true);
            } else {
              addEntityError(preguntaId, false);
            } */
            addEntityError(preguntaId, false);
          }
        } else {
          setInvalidNumberError(true);
          setErrorType("invalidNumber");
          if (isRequired) {
            addEntityError(preguntaId, true);
          }
        }
        setIsEmptyError(false);
        addEntityAnswer(preguntaId, numericValue);
      }
    } else {
      if (decimal) {
        if (/^[0-9]*\.?[0-9]*$/.test(numericValue)) {
          setInvalidNumberError(false);
        } else {
          setInvalidNumberError(true);
        }
      } else {
        if (/^-?\d+$/.test(numericValue)) {
          setInvalidNumberError(false);
        } else {
          setInvalidNumberError(true);
        }
      }
      addPreviewAnswer(preguntaId, numericValue);
    }
  };

  const inputProps: InputBaseComponentProps = {};
  if (decimal) {
    inputProps.inputMode = "decimal";
    inputProps.pattern = "^[0-9]*\\.?[0-9]*$";
  } else {
    inputProps.inputMode = "numeric";
    inputProps.pattern = "[0-9]*";
  }

  React.useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      if (value) {
        /* if (!isInRange(100, 10, Number(value))) {
          setErrorType("valueBetween01");
          setIsOutOfRangeError(true);
        } else  */
        if (decimal) {
          if (/^[0-9]*\.?[0-9]*$/.test(value)) {
            addEntityError(preguntaId, false);
            setInvalidNumberError(false);
          } else {
            addEntityError(preguntaId, true);
            setErrorType("invalidNumber");
            setInvalidNumberError(true);
          }
        } else {
          if (/^-?\d+$/.test(value)) {
            addEntityError(preguntaId, false);
            setInvalidNumberError(false);
          } else {
            setErrorType("invalidNumber");
            addEntityError(preguntaId, true);
            setInvalidNumberError(true);
          }
        }
      } else {
        setErrorType("required");
        setInvalidNumberError(false);
        setIsEmptyError(true);
      }

      globalNextButtonClicked(false);
    }
  }, [
    addEntityAnswer,
    addEntityError,
    decimal,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
    value,
  ]);

  React.useEffect(() => {
    if (isRequired) {
      addEntityError(preguntaId, Boolean(!value));
    }
  }, [addEntityError, isRequired, preguntaId, value]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TextField
          id={`${preguntaId}-numeric-input`}
          inputProps={inputProps}
          type="text"
          value={value ? value.toString() : ""}
          variant="outlined"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e.target.value)
          }
          error={isEmptyError || invalidNumberError}
        />

        {(isEmptyError || invalidNumberError) && (
          <ErrorMessage messageType={errorType} />
        )}
      </Box>
    </React.Fragment>
  );
};

export default NumberInput;
