import axios from "axios";
import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

import { BGlobalQuestionType } from "../types";

type AnswersState = {
  allQuestions: BGlobalQuestionType[];
  answers: Record<string, any>;
  contactId: string;
  currentPage: number;
  currentPageId: string;
  entityId: string;
  finishSurvey: boolean;
  isPreviewSurvey: boolean;
  previewCurrentPage: number;
  previewCurrentPageId: string;
  surveyAnswers: Record<string, any>;
  surveyEntityAnswers: Record<string, any>;
  surveyCurrentPage: number;
  surveyId: string;
  surveyIsFinished: boolean;
  surveys: string[];
  surveysCurrentPage: Record<string, any>;
  surveysPreviewCurrentPage: Record<string, any>;
  surveyUpdateData: boolean;
};

type AnswersAction = {
  addAnswer: (questionId: string, answer: any) => void;
  addEntityAnswer: (questionId: string, answer: any) => void;
  addQuestion: (questions: BGlobalQuestionType[]) => void;
  addSurvey: (surveyId: string) => void;

  goToHomePage: () => void;
  goToLastPage: (lastPage: number) => void;
  goToNextPage: () => void;
  goToPrevPage: () => void;

  sendAllAnswers: () => void;

  setContactId: (contactId: string) => void;
  setCurrentPage: (currentPage: number) => void;
  setCurrentPageId: (currentPageId: string) => void;
  setEntityId: (entityId: string) => void;
  setFinishSurvey: (finishSurvey: boolean) => void;
  setIsPreviewSurvey: (isPreviewSurvey: boolean) => void;
  setPreviewCurrentPage: (previewCurrentPage: number) => void;
  setPreviewCurrentPageId: (previewCurrentPageId: string) => void;
  setSurveyCurrentPage: (surveyId: string, currentPage: number) => void;
  setSurveyPreviewCurrentPage: (surveyId: string, currentPage: number) => void;
  setSurveyId: (surveyId: string) => void;
  setSurveyIsFisihed: (surveyIsFinished: boolean) => void;
  setSurveyUpdateData: (surveyUpdateData: boolean) => void;

  returnPreviewSurveyCurrentPage: () => number;
  returnSurveyCurrentPage: () => number;

  resetSurveyAnswers: () => void;
};

// define the initial state
const initialAnswersState: AnswersState = {
  allQuestions: [],
  answers: {},
  contactId: "",
  currentPage: 0,
  currentPageId: "",
  entityId: "",
  finishSurvey: false,
  isPreviewSurvey: true,
  previewCurrentPage: 0,
  previewCurrentPageId: "",
  surveyAnswers: {},
  surveyEntityAnswers: {},
  surveyCurrentPage: 0,
  surveyId: "",
  surveyIsFinished: false,
  surveys: [],
  surveysCurrentPage: {},
  surveysPreviewCurrentPage: {},
  surveyUpdateData: false,
};

export const useNewAnswersStore = create<AnswersState & AnswersAction>()(
  persist(
    (set, get) => ({
      ...initialAnswersState,
      addAnswer: (questionId: string, answer: any) => {
        console.log("addAnswer", answer);
        set((state) => ({
          ...state,
          answers: {
            ...state.answers,
            [get().surveyId]: {
              ...state.answers[get().surveyId],
              [questionId]: answer,
            },
          },
          surveyAnswers: {
            ...state.surveyAnswers,
            [get().surveyId]: {
              ...state.surveyAnswers[get().surveyId],
              [get().currentPageId]: {
                ...(state.surveyAnswers[get().surveyId] && {
                  ...state.surveyAnswers[get().surveyId][get().currentPageId],
                }),
                [questionId]: answer,
              },
            },
          },
        }));
      },
      addEntityAnswer: (questionId: string, answer: any) => {
        console.log("addEntityAnswer", answer);
        set((state) => ({
          ...state,
          surveyEntityAnswers: {
            ...state.surveyEntityAnswers,
            [get().entityId]: {
              ...state.surveyEntityAnswers[get().entityId],
              [get().surveyId]: {
                ...(state.surveyEntityAnswers[get().entityId] && {
                  ...state.surveyEntityAnswers[get().entityId][get().surveyId],
                }),
                [get().currentPageId]: {
                  ...(state.surveyEntityAnswers[get().entityId] &&
                    state.surveyEntityAnswers[get().entityId][get().surveyId] &&
                    state.surveyEntityAnswers[get().entityId][get().surveyId][
                      get().currentPageId
                    ] && {
                      ...state.surveyEntityAnswers[get().entityId][
                        get().surveyId
                      ][get().currentPageId],
                    }),
                  [questionId]: answer,
                },
              },
            },
          },
        }));
      },
      addQuestion: (questions: BGlobalQuestionType[]) => {
        console.log("addQuestion");
        set((state) => {
          const combinedArray = questions
            .concat(state.allQuestions)
            .filter((obj, index, self) => {
              return index === self.findIndex((o) => o.Id === obj.Id);
            });

          return {
            ...state,
            allQuestions: combinedArray,
          };
        });
      },
      addSurvey: (surveyId: string) => {
        console.log("addSurvey");
        set((state) => ({ ...state, surveys: [...state.surveys, surveyId] }));
      },
      goToHomePage: () => {
        console.log("goToHomePage");
        set((state) => ({ ...state, currentPage: 0 }));
      },
      goToLastPage: (lastPage: number) => {
        console.log("goToLastPage");
        set((state) => ({ ...state, currentPage: lastPage }));
      },
      goToNextPage: () => {
        console.log("goToNextPage");
        set((state) => ({ ...state, currentPage: get().currentPage + 1 }));
      },
      goToPrevPage: () => {
        console.log("goToPrevPage");
        set((state) => ({ ...state, currentPage: get().currentPage - 1 }));
      },
      sendAllAnswers: async () => {
        console.log("sendAllAnswers");
        const todasRespuestas =
          get().surveyEntityAnswers[get().entityId][get().surveyId];
        const todasPaginas = Object.keys(todasRespuestas);

        const todasPreguntas = get().allQuestions;

        let preguntasUpload: BGlobalQuestionType[] = [];

        let uploadFiles: any[] = [];

        for (let index = 0; index < todasPreguntas.length; index++) {
          const pregunta = todasPreguntas[index];
          if (
            pregunta.BGlobalType.Description === "mupload" ||
            pregunta.BGlobalType.Description === "upload"
          ) {
            preguntasUpload.push(pregunta);
          }
        }

        const setUploadQuestions = () => {
          for (const currentPageId in todasRespuestas) {
            const questions: string[] = Object.keys(
              todasRespuestas[currentPageId]
            );

            const findedQuestion = preguntasUpload.find((preguntaUpload) => {
              return questions.includes(preguntaUpload.Id);
            });

            if (findedQuestion) {
              for (let index = 0; index < preguntasUpload.length; index++) {
                const preguntaUpload = preguntasUpload[index];
                if (questions.includes(preguntaUpload.Id)) {
                  uploadFiles.push({
                    type: preguntaUpload.BGlobalType.Description,
                    files: todasRespuestas[currentPageId][preguntaUpload.Id],
                  });
                }
              }
            }
          }
        };

        if (preguntasUpload.length > 0) {
          setUploadQuestions();
        }

        const formData = new FormData();

        if (uploadFiles.length > 0) {
          for (let index = 0; index < uploadFiles.length; index++) {
            const uploadFilesArray = uploadFiles[index];
            if (uploadFilesArray.type === "mupload") {
              for (
                let index = 0;
                index < uploadFilesArray.files.length;
                index++
              ) {
                const uploadArray = uploadFilesArray.files[index];
                formData.append("files", uploadArray);
              }
            } else {
              formData.append("file", uploadFilesArray.files);
            }
          }
        }

        formData.append("pagesList", JSON.stringify(todasPaginas));

        formData.append("entityId", get().entityId);

        formData.append("questions", JSON.stringify(get().allQuestions));

        formData.append(
          "surveyAnswers",
          JSON.stringify(get().surveyEntityAnswers[get().entityId])
        );

        formData.append("surveyId", get().surveyId);

        axios
          .post(`${process.env.REACT_APP_DO_URL}/enviarRespuestas`, formData)
          .then((res) => console.log("RESPUESTAS ENVIADAS", res.data))
          .catch((err) => console.log("ERROR ENVIAR RESPUESTAS", err));
      },
      setContactId: (contactId: string) => {
        console.log("setContactId");
        set((state) => ({ ...state, contactId }));
      },
      setCurrentPage: (currentPage: number) => {
        console.log("setCurrentPage");
        set((state) => ({ ...state, currentPage }));
      },
      setCurrentPageId: (currentPageId) => {
        console.log("setCurrentPageId");
        set((state) => ({
          ...state,
          currentPageId,
        }));
      },
      setFinishSurvey: (finishSurvey: boolean) => {
        console.log("setFinishSurvey");
        set((state) => ({
          ...state,
          finishSurvey,
        }));
      },
      setIsPreviewSurvey: (isPreviewSurvey: boolean) => {
        console.log("setIsPreviewSurvey");
        set((state) => ({
          ...state,
          isPreviewSurvey,
        }));
      },
      setSurveyCurrentPage: (surveyId, currentPage) => {
        console.log("setSurveyCurrentPage");
        set((state) => ({
          surveysCurrentPage: {
            ...state.surveysCurrentPage,
            [surveyId]: { currentPage },
          },
        }));
      },
      setSurveyPreviewCurrentPage: (surveyId, previewCurrentPage) => {
        console.log("setSurveyPreviewCurrentPage", previewCurrentPage);
        set((state) => ({
          surveysPreviewCurrentPage: {
            ...state.surveysPreviewCurrentPage,
            [surveyId]: { previewCurrentPage },
          },
        }));
      },
      setEntityId: (entityId: string) => {
        console.log("setEntityId");
        set((state) => ({ ...state, entityId }));
      },
      setPreviewCurrentPage: (previewCurrentPage: number) => {
        console.log("setPreviewCurrentPage");
        set((state) => ({ ...state, previewCurrentPage }));
      },
      setPreviewCurrentPageId: (previewCurrentPageId: string) => {
        console.log("setPreviewCurrentPageId");
        set((state) => ({
          ...state,
          previewCurrentPageId,
        }));
      },
      setSurveyId: (surveyId: string) => {
        console.log("setSurveyId");
        set((state) => ({ ...state, surveyId }));
      },
      setSurveyIsFisihed: (surveyIsFinished: boolean) => {
        console.log("setSurveyIsFisihed", surveyIsFinished ? "si" : "no");
        set((state) => ({ ...state, surveyIsFinished }));
      },
      setSurveyUpdateData: (surveyUpdateData) => {
        console.log("setSurveyUpdateData", surveyUpdateData ? "si" : "no");
        set((state) => ({ ...state, surveyUpdateData }));
      },
      returnSurveyCurrentPage: () => {
        return (
          get().surveysCurrentPage[get().entityId]?.currentPage ||
          get().currentPage
        );
      },
      returnPreviewSurveyCurrentPage: () => {
        return (
          get().surveysPreviewCurrentPage[get().surveyId]?.previewCurrentPage ||
          get().previewCurrentPage
        );
      },
      resetSurveyAnswers: () => {
        set(initialAnswersState);
      },
    }),
    {
      name: "new-creatio-answers-storage", // name of the item in the storage (must be unique)
      storage: createJSONStorage<AnswersState>(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);
