import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import { shallow } from "zustand/shallow";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import Grid from "@mui/material/Grid";

import ErrorMessage from "../ErrorMessage/ErrorMessage";

import { useSurveyAnswer } from "../../hooks/useSurveyAnswers";

import { useErrorsStore } from "../../store/errorStore";
import { useNewAnswersStore } from "../../store/newAnswersStore";
import { useNewPreviewAnswersStore } from "../../store/newPreviewAnswersStore";

import "dayjs/locale/es-mx";

type DateQuestionType = {
  isRequired?: boolean;
  preguntaId: string;
};

const DateQuestion: React.FC<DateQuestionType> = ({
  isRequired,
  preguntaId,
}) => {
  const [isRequiredError, setIsRequiredError] = useState<boolean>(false);
  const [invalidDateError, setInvalidDateError] = useState<boolean>(false);

  const selectedDate = useSurveyAnswer(preguntaId);

  const isPreviewSurvey = useNewAnswersStore((state) => state.isPreviewSurvey);

  const addPreviewAnswer = useNewPreviewAnswersStore(
    React.useCallback((state) => state.addPreviewAnswer, [])
  );

  const addEntityAnswer = useNewAnswersStore(
    useCallback((state) => state.addEntityAnswer, [])
  );

  const isGlobalNextButtonClicked = useErrorsStore(
    useCallback((state) => state.isGlobalNextButtonClicked, []),
    shallow
  );

  const globalNextButtonClicked = useErrorsStore(
    useCallback((state) => state.globalNextButtonClicked, [])
  );

  const addEntityError = useErrorsStore(
    useCallback((state) => state.addEntityError, [])
  );

  const handleChangeDate = useCallback(
    (newValue: dayjs.Dayjs | null) => {
      if (!isPreviewSurvey) {
        if (newValue?.isValid()) {
          addEntityError(preguntaId, false);
          setInvalidDateError(false);
        } else {
          setInvalidDateError(true);
          addEntityError(preguntaId, true);
        }
        addEntityAnswer(preguntaId, newValue);
      } else {
        addPreviewAnswer(preguntaId, newValue);
      }
      setIsRequiredError(false);
    },
    [
      addEntityAnswer,
      addEntityError,
      addPreviewAnswer,
      isPreviewSurvey,
      preguntaId,
    ]
  );

  useEffect(() => {
    if (isGlobalNextButtonClicked && isRequired) {
      if (!selectedDate) {
        addEntityError(preguntaId, true);
        setIsRequiredError(true);
        setInvalidDateError(false);
      } else if (selectedDate && !selectedDate.isValid()) {
        setIsRequiredError(false);
        setInvalidDateError(true);
      } else {
        addEntityError(preguntaId, false);
        setIsRequiredError(false);
        setInvalidDateError(false);
      }
      globalNextButtonClicked(false);
    }
  }, [
    addEntityError,
    globalNextButtonClicked,
    isGlobalNextButtonClicked,
    isRequired,
    preguntaId,
    selectedDate,
  ]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} locale="es-mx">
        <Grid container justifyContent="flex-start">
          <Grid item xs={4}>
            <DatePicker
              sx={{ width: "100%" }}
              value={selectedDate ? dayjs(selectedDate) : null}
              onChange={handleChangeDate}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

      {(isRequiredError || invalidDateError) && (
        <ErrorMessage
          messageType={isRequiredError ? "required" : "invalidDate"}
        />
      )}
    </>
  );
};

export default DateQuestion;
