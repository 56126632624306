import * as React from "react";
import { shallow } from "zustand/shallow";

import { ThemeProvider } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import DateQuestion from "../DateQuestion/DateQuestion";
import FileUploadQuestion from "../FileUploadQuestion/FileUploadQuestion";
import NewCheckboxOptions from "../NewCheckboxOptions/NewCheckboxOptions";
import NumberInput from "../NumberInput/NumberInput";
import RadioQuestion from "../RadioQuestion/RadioQuestion";
import TextInput from "../InputText/InputText";

import { useColorPreguntasStore } from "../../store/colorPreguntasStore";
import { useColorRespuestasStore } from "../../store/colorRespuestasStore";

import { createPreguntasTheme, createRespuestasTheme } from "../../utils/theme";

import { BGlobalQuestionType } from "../../types";

type CondAnswerType = {
  checkboxOptions: any[];
  preguntaId: string;
  question: BGlobalQuestionType;
  isRequired?: boolean;
  condQuestions?: any;
  condAnswers?: any;
  todasPreguntas?: any;
  radioCondQuestion?: any;
};

const CondAnswer: React.FC<CondAnswerType> = ({
  checkboxOptions,
  preguntaId,
  question,
  condAnswers,
  isRequired,
  condQuestions,
  todasPreguntas,
  radioCondQuestion,
}) => {
  const { preguntaFontColor, preguntaFontFamily } = useColorPreguntasStore(
    ({ preguntaFontColor, preguntaFontFamily }) => ({
      preguntaFontColor,
      preguntaFontFamily,
    }),
    shallow
  );

  const { respuestaBackgroundColor, respuestaFontColor, respuestaFontFamily } =
    useColorRespuestasStore(
      ({
        respuestaBackgroundColor,
        respuestaFontColor,
        respuestaFontFamily,
      }) => ({
        respuestaBackgroundColor,
        respuestaFontColor,
        respuestaFontFamily,
      }),
      shallow
    );

  const preguntasTheme = createPreguntasTheme(
    preguntaFontFamily,
    preguntaFontColor
  );

  const respuestaTheme = createRespuestasTheme(
    respuestaBackgroundColor,
    respuestaFontFamily,
    respuestaFontColor
  );

  const renderAnswerType = () => {
    const { respuesta } = radioCondQuestion;
    const { type, addComment } = respuesta;

    switch (type) {
      case "checkbox":
        return (
          <NewCheckboxOptions
            options={checkboxOptions}
            preguntaId={preguntaId}
          />
        );

      case "date":
        return <DateQuestion preguntaId={preguntaId} />;

      case "float":
      case "int":
        return (
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <NumberInput
              preguntaId={preguntaId}
              decimal={type === "float"}
              key={`${preguntaId}-numeric-input`}
            />
          </Box>
        );

      case "mupload":
      case "upload":
        return (
          <Box>
            <FileUploadQuestion
              multiple={type === "mupload"}
              preguntaId={preguntaId}
            />
          </Box>
        );

      case "qtxtmulti":
        return (
          <Box>
            <FormControl fullWidth>
              <Typography
                id={`${addComment}-label-id`}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                {addComment}
              </Typography>
              <TextField id={`${addComment}-id`} variant="filled" fullWidth />
            </FormControl>
          </Box>
        );

      case "radiobutton":
        return (
          <RadioQuestion
            radioOptions={respuesta.answers || []}
            preguntaId={preguntaId}
            isRequired={isRequired}
            condQuestions={condQuestions}
            todasPreguntas={todasPreguntas}
            isConditionalQuestion={false}
          />
        );

      case "txt":
        return <TextInput preguntaId={preguntaId} isRequired={isRequired} />;

      case "txtmulti":
        return (
          <Box>
            <TextField
              id="filled-multiline-flexible"
              multiline
              rows={4}
              variant="filled"
              fullWidth
              inputProps={{ style: { resize: "both" } }}
            />
          </Box>
        );

      default:
        return <Typography>{type}</Typography>;
    }
  };

  return (
    <React.Fragment>
      <ThemeProvider theme={preguntasTheme}>
        <Box
          sx={{
            display: "flex",
            paddingY: 2,
            placeContent: "flex-start",
            placeItems: "center",
          }}
        >
          <Box display="block">
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              {question.BGlobalQuestion || radioCondQuestion.pregunta}
            </Typography>
          </Box>
        </Box>
      </ThemeProvider>

      <ThemeProvider theme={respuestaTheme}>{renderAnswerType()}</ThemeProvider>

      <Divider />
    </React.Fragment>
  );
};

export default CondAnswer;
