import * as React from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { SketchPicker, ColorResult } from "react-color";
import { shallow } from "zustand/shallow";

import { ThemeProvider } from "@mui/material/styles";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";

import MenuFuentes from "./MenuFuentes";
import { MaterialIcons } from "../../icons/Material/MaterialIcons";

import { useColorEncuestaStore } from "../../store/colorEncuestaStore";
import { useColorTituloStore } from "../../store/colorTituloStore";
import { useColorPreguntasStore } from "../../store/colorPreguntasStore";
import { useColorRespuestasStore } from "../../store/colorRespuestasStore";
import { createEncuestaTheme } from "../../utils/theme";

type ConfigurationDrawerTypes = {
  open: boolean;
  onClose: () => void;
  surveyId?: string;
};

const ConfigurationDrawer: React.FC<ConfigurationDrawerTypes> = ({
  onClose,
  open,
  surveyId,
}) => {
  const [savingStyles, setSavingStyles] = React.useState<boolean>(false);

  const [anchorElEncuestaColorLetra, setAnchorElEncuestaColorLetra] =
    React.useState<null | HTMLElement>(null);

  const [anchorElEncuestaColorFondo, setAnchorElEncuestaColorFondo] =
    React.useState<null | HTMLElement>(null);

  const [anchorElTituloColorLetra, setAnchorEltituloColorLetra] =
    React.useState<null | HTMLElement>(null);

  const [anchorElPreguntaColorLetra, setAnchorElPreguntaColorLetra] =
    React.useState<null | HTMLElement>(null);

  const [anchorElRespuestaColorFondo, setAnchorElRespuestaColorFondo] =
    React.useState<null | HTMLElement>(null);

  const [anchorElRespuestaColorLetra, setAnchorElRespuestaColorLetra] =
    React.useState<null | HTMLElement>(null);

  const [openEcuestaCollapse, setOpenEcuestaCollapse] =
    React.useState<boolean>(false);

  const [openTituloCollapse, setOpenTituloCollapse] =
    React.useState<boolean>(false);

  const [openPreguntaCollapse, setOpenPreguntaCollapse] =
    React.useState<boolean>(false);

  const [openRespuestaCollapse, setOpenRespuestaCollapse] =
    React.useState<boolean>(false);

  const { t } = useTranslation();

  const { encuestaBackgroundColor, encuestaFontColor, encuestaFontFamily } =
    useColorEncuestaStore(
      ({ encuestaBackgroundColor, encuestaFontColor, encuestaFontFamily }) => ({
        encuestaBackgroundColor,
        encuestaFontColor,
        encuestaFontFamily,
      }),
      shallow
    );

  const {
    setEncuestaFontFamily,
    setEncuestaFontColor,
    setEncuestaBackgroundColor,
  } = useColorEncuestaStore(
    ({
      setEncuestaBackgroundColor,
      setEncuestaFontColor,
      setEncuestaFontFamily,
    }) => ({
      setEncuestaBackgroundColor,
      setEncuestaFontColor,
      setEncuestaFontFamily,
    })
  );

  const { tituloFontColor, tituloFontFamily } = useColorTituloStore(
    ({ tituloFontColor, tituloFontFamily }) => ({
      tituloFontColor,
      tituloFontFamily,
    }),
    shallow
  );

  const { setTituloFontColor, setTituloFontFamily } = useColorTituloStore(
    ({ setTituloFontColor, setTituloFontFamily }) => ({
      setTituloFontColor,
      setTituloFontFamily,
    })
  );

  const { preguntaFontColor, preguntaFontFamily } = useColorPreguntasStore(
    ({ preguntaFontColor, preguntaFontFamily }) => ({
      preguntaFontColor,
      preguntaFontFamily,
    }),
    shallow
  );

  const { setPreguntaFontColor, setPreguntaFontFamily } =
    useColorPreguntasStore(
      ({ setPreguntaFontColor, setPreguntaFontFamily }) => ({
        setPreguntaFontColor,
        setPreguntaFontFamily,
      })
    );

  const { respuestaBackgroundColor, respuestaFontColor, respuestaFontFamily } =
    useColorRespuestasStore(
      ({
        respuestaBackgroundColor,
        respuestaFontColor,
        respuestaFontFamily,
      }) => ({
        respuestaBackgroundColor,
        respuestaFontColor,
        respuestaFontFamily,
      }),
      shallow
    );

  const {
    setRespuestaBackgroundColor,
    setRespuestaFontColor,
    setRespuestaFontFamily,
  } = useColorRespuestasStore(
    ({
      setRespuestaBackgroundColor,
      setRespuestaFontColor,
      setRespuestaFontFamily,
    }) => ({
      setRespuestaBackgroundColor,
      setRespuestaFontColor,
      setRespuestaFontFamily,
    })
  );

  const handleEncuestaMenuColorLetra = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElEncuestaColorLetra(event.currentTarget);
  };

  const handleCloseMenuEcuestaColorLetra = () => {
    setAnchorElEncuestaColorLetra(null);
  };

  const handleEncuestaMenuColorFondo = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElEncuestaColorFondo(event.currentTarget);
  };

  const handleCloseMenuEcuestaColorFondo = () => {
    setAnchorElEncuestaColorFondo(null);
  };

  const handleTituloMenuColorLetra = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEltituloColorLetra(event.currentTarget);
  };

  const handleCloseTituloMenuColorLetra = () => {
    setAnchorEltituloColorLetra(null);
  };

  const handlePreguntaMenuColorLetra = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElPreguntaColorLetra(event.currentTarget);
  };

  const handleClosePreguntaMenuColorLetra = () => {
    setAnchorElPreguntaColorLetra(null);
  };

  const handleRespuestaMenuColorLetra = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElRespuestaColorLetra(event.currentTarget);
  };

  const handleCloserespuestaMenuColorLetra = () => {
    setAnchorElRespuestaColorLetra(null);
  };

  const handleRespuestaMenuColorFondo = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElRespuestaColorFondo(event.currentTarget);
  };

  const handleCloserespuestaMenuColorFondo = () => {
    setAnchorElRespuestaColorFondo(null);
  };

  const handleEncuestasFontColorChange = (color: ColorResult) => {
    setEncuestaFontColor(color.hex);
  };

  const handleEncuestasBackgroundColorChange = (color: ColorResult) => {
    setEncuestaBackgroundColor(color.hex);
  };

  const handleTituloFontColorChange = (color: ColorResult) => {
    setTituloFontColor(color.hex);
  };

  const handlePreguntasFontColorChange = (color: ColorResult) => {
    setPreguntaFontColor(color.hex);
  };

  const handleRespuestasFontColorChange = (color: ColorResult) => {
    setRespuestaFontColor(color.hex);
  };

  const handleRespuestasBackgroundColorChange = (color: ColorResult) => {
    setRespuestaBackgroundColor(color.hex);
  };

  const handleClickEcuestaCollapse = () => {
    setOpenEcuestaCollapse(!openEcuestaCollapse);
  };

  const handleClickTituloCollapse = () => {
    setOpenTituloCollapse(!openTituloCollapse);
  };

  const handleClickPreguntaCollapse = () => {
    setOpenPreguntaCollapse(!openPreguntaCollapse);
  };

  const handleClickRespuestaCollapse = () => {
    setOpenRespuestaCollapse(!openRespuestaCollapse);
  };

  const encuestaTheme = createEncuestaTheme(
    "#000000",
    "Hanken Grotesk",
    "#000000"
  );

  const saveStyles = () => {
    setSavingStyles(true);
    axios
      .post(`${process.env.REACT_APP_DO_URL}/savestyles`, {
        answerBC: respuestaBackgroundColor,
        answerFC: respuestaFontColor,
        answerFT: respuestaFontFamily,
        questionFC: preguntaFontColor,
        questionFT: preguntaFontFamily,
        titleFC: tituloFontColor,
        titleFT: tituloFontFamily,
        surveyBC: encuestaBackgroundColor,
        surveyFC: encuestaFontColor,
        surveyFT: encuestaFontFamily,
        surveyId,
      })
      .then((res) => {
        setSavingStyles(false);
        console.log("ESTILOS", res.data);
      })
      .catch((e) => {
        setSavingStyles(false);
        console.error("ERROR ESTILOS", e);
      });
  };

  return (
    <ThemeProvider theme={encuestaTheme}>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => onClose()}
        PaperProps={{ sx: { backgroundColor: "#ebe0ca" } }}
      >
        <Stack spacing={2}>
          <Box sx={{ paddingTop: 0 }}>
            <List>
              <ListItemButton onClick={handleClickEcuestaCollapse}>
                <ListItemText primary={t("messages.survey")} />
                {openEcuestaCollapse ? (
                  <MaterialIcons.ExpandLessIcon />
                ) : (
                  <MaterialIcons.ExpandMoreIcon />
                )}
              </ListItemButton>
              <Collapse in={openEcuestaCollapse} timeout="auto" unmountOnExit>
                <ListItem>
                  <ListItemIcon>
                    <MaterialIcons.TextFormatIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("messages.fontType")} />
                  <MenuFuentes
                    fuente={encuestaFontFamily}
                    handleSelectFuente={setEncuestaFontFamily}
                  />
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={handleEncuestaMenuColorLetra}>
                    <ListItemIcon>
                      <MaterialIcons.PaletteIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("messages.fontColor")} />
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "6px",
                        backgroundColor: encuestaFontColor,
                      }}
                    />
                  </ListItemButton>
                  <Menu
                    id="encuesta-color-letra-menu-appbar"
                    anchorEl={anchorElEncuestaColorLetra}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElEncuestaColorLetra)}
                    onClose={handleCloseMenuEcuestaColorLetra}
                  >
                    <MenuItem disableRipple>
                      <SketchPicker
                        color={encuestaFontColor}
                        onChange={handleEncuestasFontColorChange}
                        disableAlpha
                      />
                    </MenuItem>
                  </Menu>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={handleEncuestaMenuColorFondo}>
                    <ListItemIcon>
                      <MaterialIcons.PaletteIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("messages.bgColor")} />
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "6px",
                        backgroundColor: encuestaBackgroundColor,
                      }}
                    />
                  </ListItemButton>
                  <Menu
                    id="encuesta-color-fondo-menu-appbar"
                    anchorEl={anchorElEncuestaColorFondo}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElEncuestaColorFondo)}
                    onClose={handleCloseMenuEcuestaColorFondo}
                  >
                    <MenuItem disableRipple>
                      <SketchPicker
                        color={encuestaBackgroundColor}
                        onChange={handleEncuestasBackgroundColorChange}
                        disableAlpha
                      />
                    </MenuItem>
                  </Menu>
                </ListItem>
              </Collapse>
            </List>

            <Divider />
          </Box>
          <Box sx={{ paddingTop: 0 }}>
            <List>
              <ListItemButton onClick={handleClickTituloCollapse}>
                <ListItemText primary={t("messages.title")} />
                {openTituloCollapse ? (
                  <MaterialIcons.ExpandLessIcon />
                ) : (
                  <MaterialIcons.ExpandMoreIcon />
                )}
              </ListItemButton>
              <Collapse in={openTituloCollapse} timeout="auto" unmountOnExit>
                <ListItem>
                  <ListItemIcon>
                    <MaterialIcons.TextFormatIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("messages.fontType")} />
                  <MenuFuentes
                    fuente={tituloFontFamily}
                    handleSelectFuente={setTituloFontFamily}
                  />
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={handleTituloMenuColorLetra}>
                    <ListItemIcon>
                      <MaterialIcons.PaletteIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("messages.fontColor")} />
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "6px",
                        backgroundColor: tituloFontColor,
                      }}
                    />
                  </ListItemButton>
                  <Menu
                    id="titulo-color-letra-menu-appbar"
                    anchorEl={anchorElTituloColorLetra}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElTituloColorLetra)}
                    onClose={handleCloseTituloMenuColorLetra}
                  >
                    <MenuItem disableRipple>
                      <SketchPicker
                        color={tituloFontColor}
                        onChange={handleTituloFontColorChange}
                        disableAlpha
                      />
                    </MenuItem>
                  </Menu>
                </ListItem>
              </Collapse>
            </List>

            <Divider />
          </Box>
          <Box sx={{ paddingTop: 0 }}>
            <List>
              <ListItemButton onClick={handleClickPreguntaCollapse}>
                <ListItemText primary={t("messages.question")} />
                {openPreguntaCollapse ? (
                  <MaterialIcons.ExpandLessIcon />
                ) : (
                  <MaterialIcons.ExpandMoreIcon />
                )}
              </ListItemButton>
              <Collapse in={openPreguntaCollapse} timeout="auto" unmountOnExit>
                <ListItem>
                  <ListItemIcon>
                    <MaterialIcons.TextFormatIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("messages.fontType")} />
                  <MenuFuentes
                    fuente={preguntaFontFamily}
                    handleSelectFuente={setPreguntaFontFamily}
                  />
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={handlePreguntaMenuColorLetra}>
                    <ListItemIcon>
                      <MaterialIcons.PaletteIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("messages.fontColor")} />
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "6px",
                        backgroundColor: preguntaFontColor,
                      }}
                    />
                  </ListItemButton>
                  <Menu
                    id="pregunta-color-letra-menu-appbar"
                    anchorEl={anchorElPreguntaColorLetra}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElPreguntaColorLetra)}
                    onClose={handleClosePreguntaMenuColorLetra}
                  >
                    <MenuItem disableRipple>
                      <SketchPicker
                        color={preguntaFontColor}
                        onChange={handlePreguntasFontColorChange}
                        disableAlpha
                      />
                    </MenuItem>
                  </Menu>
                </ListItem>
              </Collapse>
            </List>

            <Divider />
          </Box>
          <Box sx={{ paddingTop: 0 }}>
            <List>
              <ListItemButton onClick={handleClickRespuestaCollapse}>
                <ListItemText primary={t("messages.answer")} />
                {openRespuestaCollapse ? (
                  <MaterialIcons.ExpandLessIcon />
                ) : (
                  <MaterialIcons.ExpandMoreIcon />
                )}
              </ListItemButton>
              <Collapse in={openRespuestaCollapse} timeout="auto" unmountOnExit>
                <ListItem>
                  <ListItemIcon>
                    <MaterialIcons.TextFormatIcon />
                  </ListItemIcon>
                  <ListItemText primary={t("messages.fontType")} />
                  <MenuFuentes
                    fuente={respuestaFontFamily}
                    handleSelectFuente={setRespuestaFontFamily}
                  />
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={handleRespuestaMenuColorLetra}>
                    <ListItemIcon>
                      <MaterialIcons.PaletteIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("messages.fontColor")} />
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "6px",
                        backgroundColor: respuestaFontColor,
                      }}
                    />
                  </ListItemButton>
                  <Menu
                    id="respuesta-color-letra-menu-appbar"
                    anchorEl={anchorElRespuestaColorLetra}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElRespuestaColorLetra)}
                    onClose={handleCloserespuestaMenuColorLetra}
                  >
                    <MenuItem disableRipple>
                      <SketchPicker
                        color={respuestaFontColor}
                        onChange={handleRespuestasFontColorChange}
                        disableAlpha
                      />
                    </MenuItem>
                  </Menu>
                </ListItem>
                <ListItem>
                  <ListItemButton onClick={handleRespuestaMenuColorFondo}>
                    <ListItemIcon>
                      <MaterialIcons.PaletteIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("messages.bgColor")} />
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "6px",
                        backgroundColor: respuestaBackgroundColor,
                      }}
                    />
                  </ListItemButton>
                  <Menu
                    id="respuesta-color-fondo-menu-appbar"
                    anchorEl={anchorElRespuestaColorFondo}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElRespuestaColorFondo)}
                    onClose={handleCloserespuestaMenuColorFondo}
                  >
                    <MenuItem disableRipple>
                      <SketchPicker
                        color={respuestaBackgroundColor}
                        onChange={handleRespuestasBackgroundColorChange}
                        disableAlpha
                      />
                    </MenuItem>
                  </Menu>
                </ListItem>
              </Collapse>
            </List>
          </Box>
        </Stack>
        <Box sx={{ display: "flex", placeContent: "center", p: 4 }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => saveStyles()}
            disabled={savingStyles}
          >
            {t("button.saveStyles")}
          </Button>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
};

export default ConfigurationDrawer;
